import * as React from 'react';
import { ChevronRight, ChevronLeft } from '@wix/wix-ui-icons-common/on-stage';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../../Tabs.semanticClassNames';
import { st, classes } from './style/TabsScrollButton.st.css';

type IScrollButtonProps = {
  onClick: () => void;
  isVisible?: boolean;
  className: string;
  dataHook: string;
  Icon: typeof ChevronRight | typeof ChevronLeft;
};

type IDirectionalScrollButtonProps = Omit<IScrollButtonProps, 'Icon'>;

const ScrollButton = React.forwardRef<HTMLDivElement, IScrollButtonProps>(
  ({ isVisible = false, onClick, className, dataHook, Icon }, ref) => (
    <div
      className={st(
        classes.root,
        { isVisible },
        className,
        formatClassNames(semanticClassNames.scrollButton),
      )}
      onClick={onClick}
      data-hook={dataHook}
      ref={ref}
    >
      <Icon className={classes.icon} />
    </div>
  ),
);

export const LeftScrollButton = React.forwardRef<
  HTMLDivElement,
  IDirectionalScrollButtonProps
>((props, ref) => {
  return <ScrollButton {...props} Icon={ChevronLeft} ref={ref} />;
});

export const RightScrollButton = React.forwardRef<
  HTMLDivElement,
  IDirectionalScrollButtonProps
>((props, ref) => {
  return <ScrollButton {...props} Icon={ChevronRight} ref={ref} />;
});

export default ScrollButton;
