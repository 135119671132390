import type { CompDefinition } from '@wix/editor-elements-types/thunderbolt';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { BaseDataItem } from '@wix/editor-elements-definitions';
import type { ExternalComponentErrorType } from '../ExternalComponent.types';
import { IExternalComponentProps as ExternalComponentDataSchemaProps } from '../ExternalComponent.props';
import {
  DEFAULT_ERROR,
  errorTypeToRenderParams,
} from './components/OnStageError/constants';
import {
  emptyStateDefaultValue,
  emptyStateTranslationKeys,
} from './components/EditorEmptyState/constants';

const EXTERNAL_COMPONENT_NAMESPACE = 'ExternalComponent';

const props = withCompInfo<
  ExternalComponentDataSchemaProps,
  CompDefinition<
    never,
    ExternalComponentDataSchemaProps & BaseDataItem,
    never,
    never
  >
>()(['compData', 'translate'], ({ compData, translate }) => {
  const translatedErrorsKeys = Object.keys(errorTypeToRenderParams).reduce(
    (acc, errorName) => {
      const key =
        errorTypeToRenderParams[errorName as ExternalComponentErrorType].key;

      if (Array.isArray(key)) {
        return {
          ...acc,
          ...key.reduce(
            (_acc, _key) => ({
              ..._acc,
              [_key]:
                translate(EXTERNAL_COMPONENT_NAMESPACE, _key) || DEFAULT_ERROR,
            }),
            {},
          ),
        };
      }

      return {
        ...acc,
        [key]: translate(EXTERNAL_COMPONENT_NAMESPACE, key) || DEFAULT_ERROR,
      };
    },
    {},
  );

  const translatedEmptyStateKeys = Object.keys(
    emptyStateTranslationKeys,
  ).reduce((acc, emptyStateKey) => {
    const translationKey =
      emptyStateTranslationKeys[
        emptyStateKey as keyof typeof emptyStateTranslationKeys
      ];
    return {
      ...acc,
      [translationKey]:
        translate(EXTERNAL_COMPONENT_NAMESPACE, translationKey) ||
        emptyStateDefaultValue[
          emptyStateKey as keyof typeof emptyStateDefaultValue
        ],
    };
  }, {});

  return {
    ...compData,
    compProps: JSON.parse(compData.compProps),
    translations: { ...translatedErrorsKeys, ...translatedEmptyStateKeys },
  };
});

export default createComponentMapperModel({
  props,
});
