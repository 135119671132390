import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import {
  buildGalleryPatternRules,
  formatPattern,
} from './FastGallery.mapperUtils';

const props = withCompInfo<any, any>()(['compData'], ({ compData }) => {
  const { items } = compData;
  return { items };
});

const css = withCompInfo<any, any>()(
  ['styleProperties', 'compId'],
  ({ styleProperties, compId }) => {
    const {
      pattern = '[2,-1,1],[1,2,1]',
      flip = false,
      mode = 'vertical',
      gap = 0,
    } = styleProperties;
    const patternFormatted = formatPattern(pattern);
    const rulesToAdd = buildGalleryPatternRules(
      compId,
      patternFormatted,
      'container',
      'item',
      flip as boolean,
      mode as any,
      gap as number,
    );

    return `${rulesToAdd}`;
  },
);

export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, css, stateRefs });
