import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getScaledFont,
  isSupportSpExperimentOn,
  migrateFields,
  supportSpSpec,
  LogicalAlignment,
  PhysicalAlignment,
  WithInherit,
  castFromInheritIfNeeded,
  convertPhysicalInputAlignmentToLogical,
  addUnitToEveryField,
  parseValueWithUnit,
  getOppositeAlign,
  getHeightInPixels,
} from '@wix/editor-elements-common-utils';
import {
  convertPhysicalInputAlignmentToDirection,
  getInputHeight,
  getLabelPaddingsValues,
  getRequiredIndicationDisplay,
} from '@wix/editor-elements-common-utils/src/commons/inputUtils';
import { Spx } from '@wix/editor-elements-types/thunderbolt';
import { migratedSPFields } from '@wix/thunderbolt-elements/src/components/TextInput/viewer/constants';
import {
  ComboBoxInputDefinition,
  ComboBoxInputSdkData,
  IComboBoxInputMapperProps,
  IComboBoxInputCSSVars,
  ComboBoxInputMapperDefinition,
  TextPaddingInputCssVars,
} from '../ComboBoxInput.types';
import { TEXT_PADDING_BASE_OFFSET, translationKeys } from '../constants';

const isExperimentOpen = (experiment: boolean | string) =>
  String(experiment) === 'true';

export const props = withCompInfo<
  IComboBoxInputMapperProps,
  ComboBoxInputDefinition,
  IComboBoxInputMapperProps
>()(
  ['compProps', 'translate', 'deviceType', 'experiments'],
  ({ compProps, translate, deviceType, experiments }, carmiData) => {
    return {
      ...carmiData,
      designableList: isExperimentOpen(
        experiments['specs.thunderbolt.designableListForMobileDropdown'],
      )
        ? compProps.designableList
        : carmiData.designableList,
      autocomplete: compProps.autocomplete,
      deviceType,
      errorMessageType: compProps.errorMessageType,
      translations: {
        errorMessage: translate(
          translationKeys.NAMESPACE,
          translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
        ),
      },
      keepInputHeightEnabled: isExperimentOpen(
        experiments['specs.thunderbolt.keepTextInputHeight'],
      ),
    };
  },
);

const minimumLabelFontSize = 16;

type TextPaddingInputCssNumericVars = Record<
  keyof TextPaddingInputCssVars,
  number
>;

const getTextPadding = (
  alignment: LogicalAlignment,
  textPadding: number | string,
): TextPaddingInputCssNumericVars => {
  const { value } = parseValueWithUnit(String(textPadding), {
    fallbackValue: { value: 0, unit: 'px' },
  });
  const isCenter = alignment === 'center';

  if (isCenter) {
    return {
      '--textPaddingInput_start': TEXT_PADDING_BASE_OFFSET,
      '--textPaddingInput_end': TEXT_PADDING_BASE_OFFSET,
    };
  }

  return {
    [`--textPaddingInput_${alignment}`]:
      alignment === 'end' ? value + TEXT_PADDING_BASE_OFFSET : value,
    [`--textPaddingInput_${getOppositeAlign(alignment)}`]:
      TEXT_PADDING_BASE_OFFSET,
  } as TextPaddingInputCssNumericVars;
};

export const css = withCompInfo<
  IComboBoxInputCSSVars,
  ComboBoxInputMapperDefinition,
  {}
>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'compSingleLayout',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
    'isOneDocMigrated',
  ],
  ({
    compData,
    compProps,
    compLayout,
    compSingleLayout,
    styleProperties,
    isMobileView,
    hasResponsiveLayout,
    siteFonts,
    formatCssValue,
    experiments,
    siteFontsSpxResolved,
    isOneDocMigrated,
  }) => {
    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      errorDirection = 'inherit',
    } = compData;

    const { inputMobileFontSize, labelMobileFontSize, inputHeightMobile } =
      compProps;
    const isSupportSpOn = isSupportSpExperimentOn(experiments);

    const compInfo = {
      compLayout,
      styleProperties,
      siteFonts,
      isMobileView,
      siteFontsSpxResolved: isSupportSpOn ? siteFontsSpxResolved : undefined,
    };

    const {
      spx,
      align,
      labelAlign,
      inputAlign,
      labelMargin,
      labelPadding,
      textPadding,
      inputHeight,
    } = styleProperties as Record<string, string> & {
      align: LogicalAlignment;
      inputAlign: WithInherit<LogicalAlignment>;
      labelAlign: WithInherit<LogicalAlignment>;
      labelMargin: number;
      labelPadding: number | string;
      textPadding: number | string;
      spx?: Spx;
      inputHeight?: number;
    };

    const calculatedLabelAlign = castFromInheritIfNeeded(labelAlign, align);

    const labelPaddingsValues = getLabelPaddingsValues({
      labelPadding,
      align: calculatedLabelAlign,
    });

    const labelPaddingVars = addUnitToEveryField(
      labelPaddingsValues,
      labelPadding,
      {
        formatCssValueWithSpx: formatCssValue,
        spx,
      },
    );

    const calculatedInputAlign = castFromInheritIfNeeded(inputAlign, align);

    const textPaddingValues = getTextPadding(calculatedInputAlign, textPadding);

    const textPaddingVars = addUnitToEveryField(
      textPaddingValues,
      textPadding,
      {
        formatCssValueWithSpx: formatCssValue,
        spx,
      },
    );

    const sharedVars = {
      '--fnt': getScaledFont(compInfo, 'fnt', inputMobileFontSize),
      '--fntlbl': getScaledFont(
        compInfo,
        'fntlbl',
        labelMobileFontSize,
        minimumLabelFontSize,
      ),
      '--align': align,
      '--direction': direction,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--errorDirection': errorDirection,
      '--arrowInsetInlineStart': 'auto',
      '--arrowInsetInlineEnd': '0',
      '--labelMarginBottom': isSupportSpOn
        ? formatCssValue(labelMargin, spx)
        : `${labelMargin}px`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      ...labelPaddingVars,
      ...textPaddingVars,
    };

    if (hasResponsiveLayout) {
      return sharedVars;
    }

    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;

    return {
      ...sharedVars,
      ...(!isOneDocMigrated && {
        height: 'auto',
      }),
      '--inputHeight': getInputHeight(
        {
          inputHeightProps: { inputHeightMobile, inputHeight },
          height,
          isMobileView,
        },
        isSupportSpOn ? formatCssValue : undefined,
        spx,
      ),
    };
  },
  [
    migrateFields([
      {
        experiment: supportSpSpec,
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [...migratedSPFields, 'textAlignment'],
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: textAlignment =>
          convertPhysicalInputAlignmentToLogical(
            textAlignment as PhysicalAlignment,
          ),
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'direction' }],
        enhancer: textAlignment => {
          return convertPhysicalInputAlignmentToDirection(
            textAlignment as PhysicalAlignment,
          );
        },
      },
    ]),
  ],
);

export const sdkData = withCompInfo<
  ComboBoxInputSdkData,
  ComboBoxInputDefinition
>()(['experiments'], ({ experiments }) => {
  const { 'specs.thunderbolt.dropdownOptionStyle': optionStyleExperiment } =
    experiments;

  return {
    isOptionStyleEnabled: isExperimentOpen(optionStyleExperiment),
  };
});

const stateRefs = withStateRefsValues(['scopedClassName']);

export default createComponentMapperModel({ props, css, sdkData, stateRefs });
