import classnames from 'clsx';
import * as React from 'react';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../../../Container.semanticClassNames';
import {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { ContainerLogic } from '../../shared/ContainerLogic';
import styles from './ScotchDoubleVerticalArea.scss';

const ScotchDoubleVerticalArea: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return (
    <ContainerLogic
      {...props}
      ref={ref}
      className={classnames(styles.root, props.className)}
      renderSlot={({ containerChildren }) => (
        <>
          <div
            className={classnames(
              styles.bg,
              formatClassNames(
                semanticClassNames.root,
                ...(props.customClassNames || []),
              ),
            )}
          />
          {containerChildren}
          <div className={styles.scotchL} />
          <div className={styles.scotchR} />
        </>
      )}
    />
  );
};

export default React.forwardRef(ScotchDoubleVerticalArea);
