import * as React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import { ConsentPolicyWrapper } from '@wix/thunderbolt-elements/src/components/ConsentPolicyWrapper/viewer/ConsentPolicyWrapper';
import {
  GoogleMapProps,
  IGoogleMapsImperativeActions,
} from '../../../GoogleMap.types';

import semanticClassNames from '../../../GoogleMap.semanticClassNames';
import style from '../../style/GoogleMap.scss';
import GoogleMap from '../../GoogleMap';

const GoogleMapWithConsentWrapper: React.ForwardRefRenderFunction<
  IGoogleMapsImperativeActions,
  GoogleMapProps
> = (props, forwardRef) => {
  const { className, customClassNames = [], skin } = props;

  return (
    <ConsentPolicyWrapper
      componentConsentPolicy={{ analytics: true, functional: true }}
      rootClassName={classNames(
        style[skin],
        style.wixIframe,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      {...props}
    >
      <GoogleMap ref={forwardRef} {...props}></GoogleMap>
    </ConsentPolicyWrapper>
  );
};

export default React.forwardRef(GoogleMapWithConsentWrapper);
