import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  WRichTextDefinition,
  WRichTextProps,
} from '@wix/thunderbolt-components';
import { getHeightCSS, sanitizeHTML } from '@wix/editor-elements-common-utils';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
  WRichTextCSSVars,
  injectTextSemanticClassName,
} from './WRichText.mapper';

const props = composeMapper<
  PreviewWrapperProps<WRichTextProps>,
  WRichTextDefinition,
  WRichTextProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'componentViewMode',
      'compProps',
      'hasResponsiveLayout',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const { verticalText } = dependencies.compProps || {};
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState, componentViewMode, hasResponsiveLayout } =
      dependencies;
    // Using `carmiData` inside the previewMapper is discouraged in general and will be removed once
    // this refactor ticket is complete - https://jira.wixpress.com/browse/ECL-1863
    const { html } = carmiData;

    const isEditorMode = componentViewMode === 'editor';

    const shouldWrapText = !hasResponsiveLayout;

    const htmlWithSemanticClasses = injectTextSemanticClassName(
      sanitizeHTML(html),
    );

    return {
      ...viewerProps,
      html: htmlWithSemanticClasses,
      previewWrapperProps: {
        isEditorMode,
        compPreviewState,
        verticalText,
        shouldWrapText,
      },
    };
  },
);

type WRichTextPreviewCSSVars = WRichTextCSSVars & {
  'pointer-events'?: 'auto';
  '--height'?: string;
  height?: `var(--height)`;
};

const css = composeMapper<
  WRichTextPreviewCSSVars,
  WRichTextDefinition,
  WRichTextCSSVars
>()(
  viewerCssMapper,
  {
    add: [
      'componentViewMode',
      'compLayout',
      'compSingleLayout',
      'hasResponsiveLayout',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const {
      componentViewMode,
      compLayout,
      compSingleLayout,
      hasResponsiveLayout,
    } = dependencies;
    const viewerCss = resolver(dependencies, carmiData);
    const shouldWrapText = !hasResponsiveLayout;

    const heightCSS = getHeightCSS(compLayout, compSingleLayout);

    const wrapperCssVars = {
      '--height': heightCSS,
      height: 'var(--height)' as const,
    };

    return {
      ...viewerCss,
      ...(componentViewMode === 'editor' && { 'pointer-events': 'auto' }),
      ...(shouldWrapText && wrapperCssVars),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
