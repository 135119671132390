import React from 'react';
import { platformIds } from '../constants';
import type { CopyButtonWrapperProps } from '../types';
import { copyToClipboard } from '../utils/clipBoard';
import { classes, st } from './style/ShareButton.component.st.css';
import { classes as shareButtonsClasses } from './style/ShareButtons.component.st.css';

const CopyButtonWrapper: React.FC<CopyButtonWrapperProps> = ({
  id,
  children,
  url,
  ariaLabel,
  onCopyButtonClicked,
}) => {
  const _onClick = () =>
    copyToClipboard(decodeURI(url)).then(onCopyButtonClicked);

  return (
    <div className={st(shareButtonsClasses.container)}>
      <button
        id={id}
        className={st(classes.root, shareButtonsClasses.button)}
        data-testid={platformIds.copyButton}
        onClick={_onClick}
        aria-label={ariaLabel}
        aria-live="assertive"
      >
        {children}
      </button>
    </div>
  );
};

export default CopyButtonWrapper;
