import { useRef } from 'react';
import { IAddressInputProps } from '../../components/AddressInput/AddressInput.types';
import {
  InternalAddress,
  CommonAddress,
  Suggestion,
  Predictions,
  Prediction,
} from './types';

const resolveToEmptyObject = () => Promise.resolve({});

const omitFalsyValues = (
  address: InternalAddress,
): Partial<InternalAddress> => {
  return Object.entries(address).reduce(
    (acc: InternalAddress, [key, value]: [string, any]) => {
      if (value) {
        return { ...acc, [key]: value };
      }
      return acc;
    },
    {},
  );
};

const toSuggestions = (predictions: Predictions): Array<Suggestion> =>
  predictions.map((prediction: Prediction) => ({
    id: prediction.searchId || '',
    value: prediction.description || '',
  }));

const convertToPartialAddress = (
  atlasResponse: CommonAddress = {},
): Partial<InternalAddress> => {
  const address: InternalAddress = {
    formatted: atlasResponse.formattedAddress,
    streetAddress: atlasResponse.streetAddress,
    subdivision: atlasResponse.subdivision,
    subdivisions: atlasResponse.subdivisions,
    city: atlasResponse.city,
    country: atlasResponse.country,
    postalCode: atlasResponse.postalCode,
    ...(atlasResponse.geocode
      ? {
          location: {
            latitude: atlasResponse.geocode.latitude || 0,
            longitude: atlasResponse.geocode.longitude || 0,
          },
        }
      : {}),
  };

  return omitFalsyValues(address);
};

export const useAtlasHandler = ({
  country,
  getPlace = resolveToEmptyObject,
  predict = resolveToEmptyObject,
  translations,
  onSuggestionsUpdate,
}: Pick<
  IAddressInputProps,
  'country' | 'getPlace' | 'predict' | 'onSuggestionsUpdate' | 'translations'
>) => {
  const lastRequestIdRef = useRef(0);

  const getGeocode = async ({
    placeId,
    rawInputValue,
  }: {
    placeId: string;
    rawInputValue: string;
  }) => {
    const newRequest = { searchId: placeId?.toString() };
    try {
      const result = await getPlace(newRequest);
      return result && result.place
        ? convertToPartialAddress(result.place.address)
        : { formatted: rawInputValue };
    } catch (e) {
      return { formatted: rawInputValue };
    }
  };

  const autoComplete = async (value: string) => {
    const requestId = ++lastRequestIdRef.current;

    const predictRequest = {
      input: value,
      ...(country
        ? {
            country_codes: [country],
          }
        : {}),
    };

    try {
      const predictResponse = await predict(predictRequest);

      if (requestId === lastRequestIdRef.current) {
        if (predictResponse.predictions && predictResponse.predictions.length) {
          onSuggestionsUpdate(toSuggestions(predictResponse.predictions || []));
        } else {
          onAutoCompleteError(translations.noResults!);
        }
      }
    } catch (errorStatus) {
      if (requestId === lastRequestIdRef.current) {
        onAutoCompleteError(translations.generalError!);
      }
    }
  };

  const onAutoCompleteError = (translation: string): void => {
    onSuggestionsUpdate([
      {
        id: '',
        value: translation,
      },
    ]);
  };

  return {
    getGeocode,
    autoComplete,
  };
};
