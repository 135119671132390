import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { isFixedPositionLegacy } from '@wix/editor-elements-common-utils';
import {
  IMenuToggleMapperProps,
  MenuToggleDefinition,
  MenuToggleCarmiProps,
  MenuToggleCarmiCSS,
} from '../MenuToggle.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './MenuToggle.mapper';

const props = composeMapper<
  PreviewWrapperProps<IMenuToggleMapperProps>,
  MenuToggleDefinition,
  MenuToggleCarmiProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      isOpen: deps.compPreviewState
        ? deps.compPreviewState === 'open'
        : viewerProps.isOpen,
      previewWrapperProps: {
        compPreviewState: deps.compPreviewState,
      },
    };
  },
);

type MenuTogglePreviewStyles = MenuToggleCarmiCSS & {
  zIndex?: string;
  'margin-left'?: string;
};

const css = composeMapper<
  MenuTogglePreviewStyles,
  MenuToggleDefinition,
  MenuToggleCarmiCSS
>()(
  viewerCssMapper,
  { add: ['shouldResetTinyMenuZIndex', 'compLayout', 'compSingleLayout'] },
  (resolver, dependencies, carmiData) => {
    const { compLayout, compSingleLayout } = dependencies;
    const viewerCss = resolver(dependencies, carmiData);
    const { shouldResetTinyMenuZIndex } = dependencies;
    const styles: MenuTogglePreviewStyles = {};

    if (shouldResetTinyMenuZIndex) {
      styles.zIndex = 'auto';
    }

    const fixedPosition = isFixedPositionLegacy(compLayout, compSingleLayout);

    if (fixedPosition) {
      styles['margin-left'] = 'calc((100% - var(--site-width)) / 2)';
    }

    return { ...styles, ...viewerCss };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
