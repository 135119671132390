import React, { memo, useEffect, useState, TransitionEvent } from 'react';
import type { IAccordionProps } from '../../types';
import { AccordionLayout } from './AccordionLayout';

const isNeededEvent = (e: TransitionEvent) =>
  e.target === e.currentTarget && e.propertyName === 'grid-template-rows';

/* hide content with a delay - only after accordion's css transition ends */
export const useHideContentAfterTransitionEnds = (isOpened: boolean) => {
  const [isContentShown, setIsContentShown] = useState(isOpened);

  useEffect(() => {
    if (isOpened) {
      setIsContentShown(true);
    }
  }, [isOpened]);

  const onTransitionEnd = (e: TransitionEvent) => {
    if (isNeededEvent(e) && !isOpened) {
      setIsContentShown(false);
    }
  };

  return {
    isContentShown,
    onTransitionEnd,
  };
};

export const AccordionAnimated: React.FC<IAccordionProps> = memo(props => (
  <AccordionLayout
    {...props}
    animationProps={useHideContentAfterTransitionEnds(props.isOpened)}
  />
));
