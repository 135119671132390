import React from 'react';
import classnames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import { SkinPageProps } from '../SkinPage';
import Page from '../../Page';
import semanticClassNames from '../../../Page.semanticClassNames';
import styles from './styles/LiftedShadowPageSkin.scss';

const LiftedShadowSkin: React.FC<SkinPageProps> = ({
  id,
  className,
  customClassNames = [],
  pageDidMount,
  onClick,
  onDblClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={classnames(styles.pageWrapper, className)}
      ref={pageDidMount}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classnames(
          styles.bg,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
      >
        <div className={styles.innerBg} />
        <div className={`${styles.left} ${styles.shd}`} />
        <div className={`${styles.right} ${styles.shd}`} />
      </div>
      <div className={styles.inlineContent}>{children()}</div>
    </div>
  );
};

const LiftedShadowPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={LiftedShadowSkin} />
);

export default LiftedShadowPage;
