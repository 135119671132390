
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ProGallery669818973",
      {"classes":{"root":"ProGallery669818973__root","container":"ProGallery669818973__container","container-prerender":"ProGallery669818973__container-prerender","pro-gallery-container":"pro-gallery-parent-container","item":"gallery-item-container","gallery-arrows":"nav-arrows-container","gallery-arrows-no-container":"nav-arrows-container only-arrows","info":"ProGallery669818973__info","fullscreen":"ProGallery669818973__fullscreen","loadmore":"show-more","x":"ProGallery669818973__x"},"vars":{"zoom-factor":"--zoom-factor","overFreemiumBanner":"--ProGallery669818973-overFreemiumBanner","overFullscreen":"--ProGallery669818973-overFullscreen"},"stVars":{"--variant-count":"var(--variant-count)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  