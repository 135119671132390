import * as React from 'react';
import classNames from 'clsx';

import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { useIsomorphicLayoutEffect } from '@wix/thunderbolt-elements/providers/useIsomorphicLayoutEffect';
import {
  IGridProps,
  IGridImperativeActions,
  GridDataFetcherDirection,
} from '../Grid.types';
import { getPageRowsRange, getRowValue } from '../utils';
import {
  PaginationType,
  ColumnLayout,
  DataSource,
  TestId,
  DEFAULT_COLUMN_WIDTH,
  DEFAULT_ROWS_PER_PAGE,
} from '../constants';
import semanticClassNames from '../Grid.semanticClassNames';
import TableHead, { ITableHead } from './TableHead';
import TableBody, { ITableBody } from './TableBody';
import Pagination from './Pagination';
import styles from './styles/Grid.scss';
import DirectionalPagination from './DirectionalPagination';

const Grid: React.ForwardRefRenderFunction<
  IGridImperativeActions,
  IGridProps
> = (props, ref) => {
  const {
    id,
    className,
    customClassNames = [],
    dataSource,
    columns,
    columnWidthUnitMap,
    rows,
    showHeader,
    pagination,
    userSelectionType,
    headerColumn,
    staticMediaUrl,
    dateFormat,
    linkTarget,
    columnLayout,
    width,
    rowHeight,
    rowHeightUnit,
    totalRowsCount,
    lastLoadedRowsCount,
    currentPage = 1,
    isLoading = false,
    selectedCell,
    selectedRow,
    onLoadRows,
    onChangePage,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    onChangeSelectedRow,
    onChangeSelectedCell,
    onRowSelect,
    onCellSelect,
    forceNextEnabled,
    forcePreviousEnabled,
  } = props;

  const isDynamicDataSource = dataSource === DataSource.Dynamic;
  const hasPagination = pagination.type === PaginationType.Pages;
  const showPagination =
    hasPagination && (isDynamicDataSource || rows.length > 0);
  const rowsPerPage = pagination.rowsPerPage || DEFAULT_ROWS_PER_PAGE;
  const hasOnlyActivePageRows = isDynamicDataSource && hasPagination;

  const visibleColumns = React.useMemo(() => {
    const filteredColumns = columns.filter(({ visible }) => visible);
    const equalColumnWidth =
      columnLayout === ColumnLayout.Equal && width && filteredColumns.length
        ? Math.ceil(width / filteredColumns.length)
        : undefined;

    return filteredColumns.map(column => ({
      ...column,
      ...(equalColumnWidth && { width: equalColumnWidth }),
    }));
  }, [columnLayout, columns, width]);

  const visibleRows = React.useMemo(() => {
    if (isDynamicDataSource || !hasPagination) {
      return rows;
    }

    // Apply basic pagination for static data source
    const [startRow, endRow] = getPageRowsRange(currentPage, rowsPerPage);
    return rows.slice(startRow, endRow);
  }, [isDynamicDataSource, hasPagination, rows, currentPage, rowsPerPage]);

  const maxPages = React.useMemo(() => {
    if (!showPagination) {
      return;
    }

    const rowsCount = isDynamicDataSource ? totalRowsCount : rows.length;

    // Dynamic data sources may not provide total rows count. In that case the
    // component will show "more" label for max page.
    return rowsCount !== undefined
      ? Math.max(1, Math.ceil(rowsCount / rowsPerPage))
      : undefined;
  }, [showPagination, isDynamicDataSource, rows, rowsPerPage, totalRowsCount]);

  const handleChangePage = (page: number) => {
    onChangePage(page);

    if (isDynamicDataSource) {
      const [startRow, endRow] = getPageRowsRange(page, rowsPerPage);
      onLoadRows?.({ startRow, endRow });
    }
  };

  const handleDirectionalChangePage = (direction: GridDataFetcherDirection) => {
    if (isDynamicDataSource) {
      onLoadRows?.({ direction, limit: rowsPerPage });
    }
  };

  const selectedRowForCurrentPage = React.useMemo((): typeof selectedRow => {
    if (selectedRow === undefined || !hasPagination) {
      return selectedRow;
    }

    const [currentPageRowOffset] = getPageRowsRange(currentPage, rowsPerPage);
    return selectedRow - currentPageRowOffset;
  }, [currentPage, rowsPerPage, hasPagination, selectedRow]);

  const selectedCellForCurrentPage = React.useMemo((): typeof selectedCell => {
    if (selectedCell === undefined || !hasPagination) {
      return selectedCell;
    }

    const [selectedCellRowIndex, selectedCellColumnIndex] = selectedCell;
    const [currentPageRowOffset] = getPageRowsRange(currentPage, rowsPerPage);

    return [
      selectedCellRowIndex - currentPageRowOffset,
      selectedCellColumnIndex,
    ];
  }, [currentPage, rowsPerPage, hasPagination, selectedCell]);

  const handleSelectCell = React.useCallback(
    ([currentPageSelectedRow, currentPageSelectedCell]: [number, number]) => {
      const [currentPageRowOffset] = getPageRowsRange(currentPage, rowsPerPage);
      const cellRowIndex = currentPageRowOffset + currentPageSelectedRow;

      onChangeSelectedCell([cellRowIndex, currentPageSelectedCell]);

      if (onCellSelect) {
        const column = visibleColumns[currentPageSelectedCell];
        const rowData =
          rows[hasOnlyActivePageRows ? currentPageSelectedRow : cellRowIndex];
        const cellData = getRowValue(rowData, column.dataPath);

        onCellSelect({
          type: 'cellSelect',
          cellData,
          cellColumnId: column.label,
          cellRowIndex,
        });
      }
    },
    [
      currentPage,
      rowsPerPage,
      onChangeSelectedCell,
      onCellSelect,
      visibleColumns,
      rows,
      hasOnlyActivePageRows,
    ],
  );

  React.useImperativeHandle(ref, () => ({
    onRowSelect: rowIndex => {
      onRowSelect?.({
        type: 'rowSelect',
        rowData: rows[rowIndex],
        rowIndex,
        compId: id,
      });
    },
  }));

  const handleSelectRow = React.useCallback(
    (currentPageSelectedRow: number) => {
      const [currentPageRowOffset] = getPageRowsRange(currentPage, rowsPerPage);
      const rowIndex = currentPageRowOffset + currentPageSelectedRow;

      onChangeSelectedRow(rowIndex);

      if (onRowSelect) {
        const rowData =
          rows[hasOnlyActivePageRows ? currentPageSelectedRow : rowIndex];
        onRowSelect({ type: 'rowSelect', rowData, rowIndex, compId: id });
      }
    },
    [
      currentPage,
      rowsPerPage,
      onChangeSelectedRow,
      onRowSelect,
      rows,
      hasOnlyActivePageRows,
      id,
    ],
  );

  const tableWidth = React.useMemo(() => {
    // Equal column layout & percentage/auto column width are implemented with table-layout: fixed and width: 100%
    if (
      columnLayout === ColumnLayout.Equal ||
      (columnWidthUnitMap != null &&
        Object.values(columnWidthUnitMap).some(unit => unit !== 'px'))
    ) {
      return;
    }

    // Set table width on manual column layout
    return visibleColumns.reduce(
      (totalWidth, { width: columnWidth = DEFAULT_COLUMN_WIDTH }) =>
        (totalWidth += columnWidth),
      0,
    );
  }, [columnLayout, visibleColumns, columnWidthUnitMap]);

  const tableBodyRef = React.useRef<ITableBody>(null);
  const tableHeadRef = React.useRef<ITableHead>(null);

  const [isVerticalScrollVisible, setVerticalScrollVisible] =
    React.useState(false);

  useIsomorphicLayoutEffect(() => {
    const tableBody = tableBodyRef.current;
    if (showHeader && tableBody) {
      setVerticalScrollVisible(tableBody.isVerticalScrollVisible());
    }
  }, [showHeader, visibleRows]);

  const handleTableBodyScroll: React.UIEventHandler = React.useCallback(
    ({ currentTarget }) => {
      // Sync table body horizontal scroll with header
      tableHeadRef.current?.setScrollLeft(currentTarget.scrollLeft);
    },
    [],
  );

  const rootClassName = classNames(
    className,
    styles.root,
    formatClassNames(semanticClassNames.root, ...customClassNames),
    {
      [styles.withVerticalScroll]: isVerticalScrollVisible,
    },
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={rootClassName}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            {showHeader && (
              <TableHead
                ref={tableHeadRef}
                tableWidth={tableWidth}
                columns={visibleColumns}
                columnWidthUnitMap={columnWidthUnitMap}
                columnLayout={columnLayout}
              />
            )}
            <TableBody
              ref={tableBodyRef}
              containerId={id}
              tableWidth={tableWidth}
              dataSource={dataSource}
              columns={visibleColumns}
              columnWidthUnitMap={columnWidthUnitMap}
              columnLayout={columnLayout}
              rows={visibleRows}
              totalRowsCount={totalRowsCount}
              lastLoadedRowsCount={lastLoadedRowsCount}
              onLoadRows={onLoadRows}
              onScroll={handleTableBodyScroll}
              isLoading={isLoading}
              pagination={pagination}
              userSelectionType={userSelectionType}
              headerColumn={headerColumn}
              staticMediaUrl={staticMediaUrl}
              dateFormat={dateFormat}
              linkTarget={linkTarget}
              rowHeight={rowHeight}
              rowHeightUnit={rowHeightUnit}
              selectedCell={selectedCellForCurrentPage}
              selectedRow={selectedRowForCurrentPage}
              onSelectRow={handleSelectRow}
              onSelectCell={handleSelectCell}
            />
          </table>
        </div>
        {isLoading && (
          <div className={styles.loaderOverlay} data-testid={TestId.Loader}>
            <span className={styles.loader}>Loading...</span>
          </div>
        )}
      </div>
      {showPagination &&
        (pagination.controlled ? (
          <DirectionalPagination
            onChangePage={handleDirectionalChangePage}
            nextEnabled={forceNextEnabled}
            previousEnabled={forcePreviousEnabled}
          />
        ) : (
          <Pagination
            currentPage={currentPage}
            maxPages={maxPages}
            onChangePage={handleChangePage}
            paginationStyle={pagination.style}
            forceNextEnabled={forceNextEnabled}
            forcePreviousEnabled={forcePreviousEnabled}
          />
        ))}
    </div>
  );
};

export default React.forwardRef(Grid);
