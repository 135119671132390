import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IRadioGroupProps,
  IRadioGroupImperativeActions,
  IRadioButtonImperativeActions,
  RadioGroupOption,
} from '../RadioGroup.types';
import semanticClassNames from '../RadioGroup.semanticClassNames';
import { InlineErrorMessage } from '../../../core/inlineErrorMessage';
import style from './style/RadioGroupDefaultSkin.scss';
import RadioButton from './RadioButton/RadioButton';

const noop = () => {};

// TODO onFocus not implemented in RadioGroup in bolt
// If we are going to implement it we need to make sure it is not triggered on changing between radio buttons
const RadioGroup: React.ForwardRefRenderFunction<
  IRadioGroupImperativeActions,
  IRadioGroupProps
> = (props, ref) => {
  const {
    id,
    className,
    customClassNames = [],
    label = '',
    value,
    options,
    required,
    isDisabled,
    translations,
    errorMessageType,
    componentViewMode,
    shouldShowValidityIndication,
    validateValueAndShowIndication = noop,
    validateValue = noop,
    onChange = noop,
    setValue = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    onBlur = noop,
    reportBiOnClick = noop,
    isValid,
  } = props;

  const validate = required ? validateValueAndShowIndication : validateValue;

  const shouldShowInlineValidityIndication =
    shouldShowValidityIndication && !isValid;

  const inputRefs = React.useRef<Array<IRadioButtonImperativeActions | null>>(
    [],
  );

  const defaultValidationMessage = translations?.validationMessage;

  const [errorMessageContent, setErrorMessageContent] = React.useState(
    defaultValidationMessage,
  );

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        const checkedItem =
          inputRefs.current?.find(_ref => _ref?.isChecked) ||
          inputRefs.current?.[0];

        checkedItem?.focus();
      },
      blur: () => {
        const focusedItem = inputRefs.current?.find(_ref => _ref?.isFocused);

        focusedItem?.blur();
      },
      setCustomValidity: message => {
        const errMsg =
          message.type === 'message' ? message.message : message.key;
        setErrorMessageContent(errMsg || defaultValidationMessage);
        inputRefs.current?.forEach(_ref => {
          _ref?.setCustomValidity(message);
        });
      },
    };
  });

  const _onChange = React.useMemo(
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      validate();
      onChange(event);
    },
    [setValue, validate, onChange],
  );

  const _onClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>,
    option: RadioGroupOption,
  ) => {
    reportBiOnClick(event, option);

    if (!isDisabled) {
      onClick(event);
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLInputElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };
  const _onBlur: React.FocusEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onBlur(event);
      validate();
    }
  };

  const containerClasses = classNames(
    style.RadioGroupDefaultSkin,
    className,
    formatClassNames(semanticClassNames.root, ...customClassNames),
    {
      [style.requiredIndication]: required && label,
    },
  );

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={containerClasses}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      onBlur={_onBlur}
    >
      <fieldset>
        <legend className={style.groupLabelLegend}>
          {label && (
            <div
              data-testid="groupLabel"
              className={classNames(
                style.groupLabel,
                formatClassNames(semanticClassNames.label),
              )}
            >
              {label}
            </div>
          )}
        </legend>
        <div data-testid="radioGroup" className={style.items}>
          {options.map((option: RadioGroupOption, index: number) => (
            <RadioButton
              ref={el => (inputRefs.current[index] = el)}
              key={index}
              {...option}
              checked={option.value === value}
              required={required}
              isDisabled={isDisabled}
              shouldShowValidityIndication={shouldShowValidityIndication}
              onClick={event => _onClick(event, option)}
              onChecked={_onChange}
              name={id}
            ></RadioButton>
          ))}
        </div>
        <InlineErrorMessage
          errorMessageType={errorMessageType}
          errorMessage={errorMessageContent}
          shouldShowValidityIndication={shouldShowInlineValidityIndication}
          translations={translations}
          componentViewMode={componentViewMode}
        />
      </fieldset>
    </div>
  );
};

export default React.forwardRef(RadioGroup);
