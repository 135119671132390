import React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import {
  IFrameEvent,
  useIFrame,
} from '@wix/thunderbolt-elements/src/providers/useIFrame/useIFrame';
import { TranslationKeys } from '../../translations';
import {
  ICustomElementPreviewComponentProps,
  ICustomElementPreviewComponentImperativeActions,
} from './CustomElementPreviewComponent.types';
import styles from './CustomElementPreviewComponent.scss';
import customElementPreviewIframe from './assets/CustomElementPreviewIframe.html?resource';

const PremiumBanner: React.FC<
  Pick<
    ICustomElementPreviewComponentProps,
    'isPremiumUser' | 'hasDomainFeature' | 'translations'
  >
> = props => {
  const { isPremiumUser, hasDomainFeature, translations } = props;

  const getTranslation = () => {
    if (!isPremiumUser) {
      return translations?.upgradePremium || TranslationKeys.UpgradePremium;
    }

    if (!hasDomainFeature) {
      return translations?.connectDomain || TranslationKeys.ConnectDomain;
    }

    return translations?.upgradeNoAds || TranslationKeys.UpgradeNoAds;
  };

  const content = getTranslation();

  return <div className={styles['premium-banner']}>{content}</div>;
};

const getAttributes = (
  attributes: ICustomElementPreviewComponentProps['attributes'],
  initialAttributes: ICustomElementPreviewComponentProps['initialAttributes'],
) => {
  const iframeAttributes = attributes || {};

  try {
    const parsedInitialAttributes = JSON.parse(initialAttributes);
    return Object.assign(parsedInitialAttributes, iframeAttributes);
  } catch (err) {
    return iframeAttributes;
  }
};

const EMPTY_EVENT_NAMES: Array<string> = [];

const CustomElementPreviewComponent: React.ForwardRefRenderFunction<
  ICustomElementPreviewComponentImperativeActions,
  ICustomElementPreviewComponentProps
> = (props, ref) => {
  const {
    id,
    componentViewMode,
    isPremiumUser,
    hasDomainFeature,
    isAdsFree,
    isAllowedForFreeSite,
    translations,
    events: eventNames = EMPTY_EVENT_NAMES,
    tagName,
    handleEvents,
    containerSizes,
    previewUrl,
    scriptType,
    attributes,
    initialAttributes,
    shouldReload = true,
    isWixBlocks,
    isDescendantOfBlocksWidget,
    className,
    fixHeight,
    updateMinHeight,
    sandbox,
    shouldUpdateHeight,
  } = props;

  const [canRenderIframe, setCanRenderIFrame] = React.useState(false);
  React.useEffect(() => setCanRenderIFrame(true), []);

  const iframeAttributes = React.useMemo(
    () => getAttributes(attributes, initialAttributes),
    [attributes, initialAttributes],
  );

  const handleMessage = React.useCallback<(iFrameEvent: IFrameEvent) => void>(
    ({ type, payload }) => {
      if (type === 'load') {
        sendMessage({
          type: 'load',
          tagName,
          scriptType,
          attributes: iframeAttributes,
          eventNames,
          style: {
            width: containerSizes?.width,
            height: containerSizes?.height,
          },
          url: previewUrl,
          shouldUpdateHeight,
        });
      } else if (type === 'message' && typeof payload === 'object') {
        const { messageType, eventName, detail } = payload;

        if (messageType === 'iframe_web_component_trigger_event') {
          handleEvents?.({ type: eventName, detail });
        }
        if (messageType === 'resize_custom_element') {
          const { height } = detail;
          if (typeof height === 'number') {
            fixHeight?.({ height });
          }
        }
        if (messageType === 'update_content_height') {
          const { height } = detail;
          updateMinHeight?.({ height });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tagName,
      iframeAttributes,
      eventNames,
      containerSizes?.width,
      containerSizes?.height,
      previewUrl,
      scriptType,
      handleEvents,
    ],
  );

  const [iframeRef, sendMessage] = useIFrame({
    reducer: handleMessage,
  });

  React.useEffect(() => {
    sendMessage({ type: 'reload' });
  }, [
    sendMessage,
    tagName,
    eventNames,
    containerSizes?.width,
    containerSizes?.height,
    previewUrl,
    scriptType,
  ]);

  React.useEffect(
    () => {
      sendMessage({
        type: shouldReload ? 'reload' : 'change',
        attributes: iframeAttributes,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [iframeAttributes],
  );

  /**
   * This useImperativeHandle returns public method which will be used by the
   * Custom Element feature in TB for the flow, when content of the file
   * in corvid were changed.
   *
   * In a case of such changes, file will have a new content, but URL to this
   * file will stay the same. Because of this iframe will not refetch data.
   *
   * So to update CustomElementPreviewComponent, 'dsCustomElementComponent' feature in TB
   * will call "onCorvidContentUpdate" and we will reload iframe.
   *
   * Feature https://github.com/wix-private/thunderbolt/blob/master/packages/feature-wix-custom-element-component/src/ds/dsCustomElementComponent.ts
   */

  React.useImperativeHandle(ref, () => ({
    onCorvidContentUpdate: () => {
      sendMessage({ type: 'reload' });
    },
  }));

  const shouldRenderPremiumBanner =
    !isWixBlocks &&
    !isDescendantOfBlocksWidget &&
    componentViewMode === 'editor' &&
    !isAllowedForFreeSite &&
    (!isPremiumUser || !hasDomainFeature || !isAdsFree);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        styles['custom-element-preview-container'],
        className,
      )}
      data-testid="custom-element"
    >
      {canRenderIframe && (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          src={customElementPreviewIframe}
          ref={iframeRef}
          sandbox={sandbox}
        />
      )}
      {shouldRenderPremiumBanner ? (
        <PremiumBanner
          isPremiumUser={isPremiumUser}
          hasDomainFeature={hasDomainFeature}
          translations={translations}
        />
      ) : null}
    </div>
  );
};

export default React.forwardRef(CustomElementPreviewComponent);
