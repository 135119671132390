import * as React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import SiteButton from '../../SiteButton';
import semanticClassNames from '../../../SiteButton.semanticClassNames';
import skinsStyle from './styles/skins.scss';

const ShinyButtonInvertedButtonSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    {
      wrapperProps,
      linkProps,
      a11yProps,
      elementType,
      label,
      onFocus,
      onBlur,
      customClassNames = [],
    },
    ref,
  ) => {
    return (
      <div
        {...wrapperProps}
        className={classNames(
          skinsStyle.ShinyButtonInverted,
          wrapperProps.className,
        )}
      >
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={classNames(
            skinsStyle.link,
            formatClassNames(semanticClassNames.root, ...customClassNames),
          )}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <div className={skinsStyle.labelwrapper}>
            <span
              className={classNames(
                skinsStyle.label,
                formatClassNames(semanticClassNames.buttonLabel),
              )}
            >
              {label}
            </span>
          </div>
        </SiteButtonContent>
      </div>
    );
  },
);

const ShinyButtonInvertedButton: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ShinyButtonInvertedButtonSkin} ref={ref} />
);

export default React.forwardRef(ShinyButtonInvertedButton);
