const semanticClassNames = {
  root: 'fastGallery',
  container: 'fastGallery__container',
  item: 'fastGallery__item',
  image: 'fastGallery__image',
  title: 'fastGallery__title',
  description: 'fastGallery__description',
} as const;

export default semanticClassNames;
