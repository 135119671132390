import * as React from 'react';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../../Checkbox.semanticClassNames';

const Checkmark: React.FC<{}> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 7.40011 5.68606"
      className={formatClassNames(semanticClassNames.icon)}
    >
      <path d="M2.55016,5.68606c-.01709,0-.03369-.001-.05078-.002a.7.7,0,0,1-.51758-.28955L.1317,2.82326a.7.7,0,1,1,1.13672-.81738l1.36133,1.8916L6.19713.2134A.69995.69995,0,0,1,7.203,1.187L3.05309,5.47268A.7.7,0,0,1,2.55016,5.68606Z" />
    </svg>
  );
};

export default Checkmark;
