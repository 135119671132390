import * as React from 'react';
import { SiteMembersTranslations } from '@wix/editor-elements-library/src/components/MemberLoginDialog/viewer/utils';
import { ILink } from '../SignUpDialog.types';
import style from './style/style.scss';
import { SignUpDialogTranslationKeys as keys, testIds } from './constants';
import { PolicyLink } from './PolicyLink';

export const Policies: React.FC<
  {
    isPrivacyPolicyNeeded: boolean;
    isTermsOfUseNeeded: boolean;
    privacyPolicyLink: ILink;
    termsOfUseLink: ILink;
    onCloseDialogCallback: () => void;
  } & SiteMembersTranslations
> = ({
  translations,
  isPrivacyPolicyNeeded,
  isTermsOfUseNeeded,
  privacyPolicyLink,
  termsOfUseLink,
  onCloseDialogCallback,
}) => {
  const content = translations[keys.policies.content];
  const privacyLinkLabel = translations[keys.policies.privacyLink];
  const and = translations[keys.policies.and];
  const termsOfUseLinkLabel = translations[keys.policies.termsOfUseLink];

  return (
    <div className={style.policies}>
      <span data-testid={testIds.policies.content}>{content}</span>
      {isTermsOfUseNeeded && (
        <PolicyLink
          onCloseDialogCallback={onCloseDialogCallback}
          privacyLinkLabel={termsOfUseLinkLabel}
          testId={testIds.policies.termsOfUseLink}
          link={termsOfUseLink}
        />
      )}
      {isPrivacyPolicyNeeded && isTermsOfUseNeeded && (
        <span data-testid={testIds.policies.and}>{and}</span>
      )}
      {isPrivacyPolicyNeeded && (
        <PolicyLink
          onCloseDialogCallback={onCloseDialogCallback}
          privacyLinkLabel={privacyLinkLabel}
          testId={testIds.policies.privacyPolicyLink}
          link={privacyPolicyLink}
        />
      )}
    </div>
  );
};
