export const OPEN_BUTTON_DATA_SCHEMA = 'StylableButton';
export const OPEN_BUTTON_TYPE = 'wixui.HamburgerOpenButton';
export const OPEN_BUTTON_PROP_TYPES = 'HamburgerOpenButtonProperties';

export const CLOSE_BUTTON_DATA_SCHEMA = 'StylableButton';
export const CLOSE_BUTTON_TYPE = 'wixui.HamburgerCloseButton';
export const CLOSE_BUTTON_PROP_TYPES = 'HamburgerCloseButtonProperties';

export const OVERLAY_TYPE = 'wixui.HamburgerOverlay';
export const OVERLAY_DATA_SCHEMA = 'wixui.HamburgerOverlay';

export const MENU_CONTAINER_TYPE = 'wixui.HamburgerMenuContainer';
export const MENU_CONTAINER_DATA_SCHEMA = 'wixui.HamburgerMenuContainer';
export const MENU_CONTAINER_PROP_TYPES = 'HamburgerMenuContainerProperties';

export const HAMBURGER_MENU_ROOT_TYPE = 'wixui.HamburgerMenuRoot';

export const HAMBURGER_SANTA_VIEWER_NAMESPACE = 'ariaLabels';

export const overrideAddPanel = (sectionNames: Record<string, string>) => [
  sectionNames.THEMED_TEXT,
  sectionNames.TITLES,
  sectionNames.PARAGRAPHS,
  sectionNames.MY_UPLOADED_IMAGES,
  sectionNames.IMAGES_COLLECTIONS,
  sectionNames.MY_SOCIAL_IMAGES,
  sectionNames.FREE_WIX_ILLUSTRATION_IMAGES,
  sectionNames.FREE_WIX_IMAGES,
  sectionNames.IMAGE_IMPORT,
  sectionNames.SITE_IMAGES,
  sectionNames.IMAGE_UPLOAD,
  sectionNames.THEMED_BUTTONS,
  sectionNames.STYLABLE_BUTTONS,
  sectionNames.ICON_BUTTONS,
  sectionNames.IMAGE_BUTTONS,
  sectionNames.DOCUMENT_BUTTONS,
  sectionNames.ITUNES_BUTTON,
  sectionNames.PAY_BUTTONS,
  sectionNames.PAYPAL_BUTTONS,
  sectionNames.THEMED_BOXES,
  sectionNames.CONTAINER_BOXES,
  sectionNames.MY_LINES,
  sectionNames.THEMED_LINES,
  sectionNames.BASIC_SHAPES,
  sectionNames.ARROWS,
  sectionNames.HORIZONTAL_LINES,
  sectionNames.VERTICAL_LINES,
  sectionNames.FEATURED,
  sectionNames.ICONS,
  sectionNames.ANIMAL_NATURE,
  sectionNames.LOGOS_AND_BADGES,
  sectionNames.CHARACTERS,
  sectionNames.TRANSPARENT_VIDEO,
  sectionNames.PRO_GALLERIES,
  sectionNames.GRID_GALLERIES,
  sectionNames.SLIDER_GALLERIES,
  sectionNames.MORE_GALLERIES,
  sectionNames.VIDEO_BOX,
  sectionNames.VIDEO_MASK,
  sectionNames.MUSIC_THEME,
  sectionNames.ITUNES_DOWNLOAD_BUTTON,
  sectionNames.THEMED_MENUS,
  sectionNames.STYLABLE_HORIZONTAL_MENUS,
  sectionNames.VERTICAL_MENUS,
  sectionNames.SUBSCRIBERS,
  sectionNames.GOOGLE_MAPS_SQUARE,
  sectionNames.GOOGLE_MAPS_GENERIC,
  sectionNames.SLIDESHOW_980,
  sectionNames.REPEATERS,
  sectionNames.REPEATERS_BLANK,
  sectionNames.SOCIAL_BAR,
  sectionNames.FACEBOOK,
  sectionNames.TWITTER,
  sectionNames.PINTEREST,
  sectionNames.VK,
  sectionNames.YOUTUBE_SOCIAL,
  sectionNames.TEXT_INPUT,
  sectionNames.LOTTIE,
  sectionNames.COLLAPSIBLE_TEXT,
  sectionNames.STYLABLE_MENUS,
  sectionNames.ACCORDION,
  sectionNames.VELO_DEV_MODE,
  sectionNames.ADD_TO_CART,
  sectionNames.SHOPPING_CART,
  sectionNames.MY_DESIGNS,
];
