import * as React from 'react';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import { LinkProps } from '@wix/thunderbolt-elements/src/components/Link/Link.types';
import { TestIDs } from '../constants';
import style from './style/MobileActionButtonMenu.scss';

export interface MobileActionsButtonMenuItem {
  label?: string;
  link?: LinkProps;
  items?: Array<MobileActionsButtonMenuItem>;
}

export interface MobileActionsButtonMenuProps {
  onClose: (e?: React.MouseEvent) => void;
  items: Array<MobileActionsButtonMenuItem>;
  title?: string;
}

type ListItemProps = MobileActionsButtonMenuItem & {
  onClick?: (e?: React.MouseEvent) => void;
  level: number;
};

type ListProps = {
  onClick?: (e?: React.MouseEvent) => void;
  items?: Array<MobileActionsButtonMenuItem>;
  level: number;
};

const ListItem = (props: ListItemProps) => {
  const { label, link, items, level, onClick } = props;
  if (items && items.length > 0) {
    return <List items={items} level={level + 1} />;
  }
  return (
    <li className={style.item}>
      <Link className={style.link} {...link} onClick={onClick}>
        {level > 0 ? `> ${label}` : label}
      </Link>
    </li>
  );
};

const List = (props: ListProps) => {
  const { items, level, onClick } = props;
  return (
    <>
      {items?.map((item: MobileActionsButtonMenuItem, idx: number) => {
        return (
          <ListItem
            key={`${level}_${idx}`}
            label={item.label}
            link={item.link}
            items={item.items}
            level={level}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

const MobileActionsButtonMenu: React.FC<MobileActionsButtonMenuProps> = ({
  items,
  title,
  onClose,
}) => {
  const handleCloseButtonClick = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    onClose();
  };

  return (
    <div className={style.menuShadow} data-testid={TestIDs.buttonMenu.root}>
      <ul
        className={style.buttonMenuPanel}
        data-testid={TestIDs.buttonMenu.panel}
      >
        <List items={items} level={0} onClick={onClose} />
      </ul>
      <div className={style.header}>
        <div
          className={style.title}
          data-testid={TestIDs.buttonMenu.headerTitle}
        >
          {title}
        </div>
        <Link
          className={style.closeButton}
          onClick={handleCloseButtonClick}
          dataTestId={TestIDs.buttonMenu.headerCloseBtn}
        />
      </div>
    </div>
  );
};

export default MobileActionsButtonMenu;
