import React from 'react';
import { Node_Type, RichContent } from 'ricos-schema';
import { truncateContent } from 'ricos-content/libs/truncateContent';
import { TrimData } from '../RichContentViewer.types';

type TruncationResult = {
  content: RichContent;
  isTruncated: boolean;
};

export const RichContentViewerTrimContainer: React.FC<{
  options: TrimData;
  content: RichContent;
  children: (content: RichContent) => React.ReactNode;
  ellipsisStyle: React.CSSProperties;
}> = ({ content, options, children, ellipsisStyle }) => {
  const {
    trimEnabled,
    trimMode,
    trimLengthParagraph,
    trimLengthWord,
    trimExclude,
    trimEllipsisEnabled,
    trimEllipsisExpandText,
    trimEllipsisCollapseText,
  } = options;

  const [collapsed, setCollapsed] = React.useState(true);

  const containerRef = React.useRef<HTMLDivElement>(null);

  if (!trimEnabled) {
    return <>{children(content)}</>;
  }

  const { content: contentTruncated, isTruncated } =
    trimMode === 'word'
      ? trimByWords(content, trimLengthWord)
      : trimByBlocks(content, trimLengthParagraph, trimExclude === 'plugins');

  const contentTruncatedNoTrailingLine =
    isTruncated && collapsed
      ? trimLastEmptyParagraph(contentTruncated as RichContent)
      : content;

  return (
    <div ref={containerRef}>
      <div style={{ height: 'auto' }}>
        {children(contentTruncatedNoTrailingLine)}

        {isTruncated && trimEllipsisEnabled ? (
          <button
            style={ellipsisStyle}
            onClick={() => {
              prepareHeightTransition(containerRef.current);
              setCollapsed(c => !c);
              setTimeout(() => fireHeightTransition(containerRef.current), 0);
            }}
            data-test="trim-ellipsis-button"
          >
            {collapsed ? trimEllipsisExpandText : trimEllipsisCollapseText}
          </button>
        ) : null}
      </div>
    </div>
  );
};

// When trimming by word, we always exclude the plugins
function trimByWords(
  content: RichContent,
  trimLength: number,
): TruncationResult {
  const resultNoPlugins = excludePlugins(content);
  const resultByWord = truncateContent(resultNoPlugins.content, {
    wordsCount: trimLength,
  });
  return {
    content: resultByWord.content as RichContent,
    isTruncated: resultByWord.isTruncated || resultNoPlugins.isTruncated,
  };
}

function trimByBlocks(
  content: RichContent,
  trimLength: number,
  trimPlugins: boolean,
): TruncationResult {
  const resultNoPlugins = trimPlugins
    ? excludePlugins(content)
    : { content, isTruncated: false };
  const resultByBlocks = truncateContent(resultNoPlugins.content, {
    blocksCount: trimLength,
  });
  return {
    content: resultByBlocks.content as RichContent,
    isTruncated: resultByBlocks.isTruncated || resultNoPlugins.isTruncated,
  };
}

// Trailing empty paragraph is not always present so the padding between content and ellipsis is not predicable, let's remove it
function trimLastEmptyParagraph(content: RichContent): RichContent {
  const lastNode = content.nodes[content.nodes.length - 1];
  if (lastNode.type === Node_Type.PARAGRAPH && lastNode.nodes.length === 0) {
    return {
      ...content,
      nodes: content.nodes.slice(0, -1),
    };
  }
  return content;
}

// Plugins accessible via formatting toolbar
const allowedNodeList = [
  Node_Type.PARAGRAPH,
  Node_Type.HEADING,
  Node_Type.BLOCKQUOTE,
  Node_Type.ORDERED_LIST,
  Node_Type.BULLETED_LIST,
  Node_Type.CODE_BLOCK,
];

// Filers out all plugins accessible via 'plugins' menu and leaves the ones accessible via formatting toolbar
function excludePlugins(content: RichContent): {
  content: RichContent;
  isTruncated: boolean;
} {
  const filteredNodes = content.nodes.filter(n =>
    allowedNodeList.includes(n.type),
  );
  const isTruncated = filteredNodes.length !== content.nodes.length;
  return {
    content: isTruncated
      ? {
          ...content,
          nodes: filteredNodes,
        }
      : content,
    isTruncated,
  };
}

// The function gives bigger duration for bigger heights, but it is logarithmic
// so the very big content would still expand pretty fast
function getDuration(height: number): number {
  // For interpolation, find scaling factors A and B such that:
  // y1 = A * log(x1) + B
  // y2 = A * log(x2) + B
  // Solving these gives: A = (y1 - B) / (log(x1)), B = y2 - A * log(x2)
  // A = (y1 - y2) / (log(x1) - log(x2)), B = y2 - A * log(x2)
  const x1 = 1000;
  const x2 = 2000;
  const y1 = 0.2;
  const y2 = 0.5;
  const A = y1 - y2 / (Math.log(x1) - Math.log(x2));
  const B = y2 - A * Math.log(x2);

  return Math.max(0.1, A * Math.log(height) + B);
}

const prepareHeightTransition = (container: HTMLElement | null) => {
  if (!container) {
    return;
  }
  container.style.height = container.getBoundingClientRect().height + 'px';
  container.style.overflow = 'hidden';
};

const fireHeightTransition = (container: HTMLElement | null) => {
  if (!container) {
    return;
  }
  const initialHeight = container.style.height;
  const finalHeight =
    container.children[0].getBoundingClientRect().height + 'px';
  const heightDiff = parseInt(finalHeight, 10) - parseInt(initialHeight, 10);
  const direction = heightDiff > 0 ? 'grow' : 'shrink';
  const duration = direction === 'grow' ? getDuration(heightDiff) : 0.1;
  container.style.transition = `height ${duration.toFixed(1)}s linear`;
  container.style.height = finalHeight;
  const cleanup = () => {
    container.style.height = 'auto';
    container.style.removeProperty('transition');
    container.style.removeProperty('overflow');
    container.removeEventListener('transitionend', cleanup);
  };
  container.addEventListener('transitionend', cleanup);
};
