import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ImageXProps,
  ImageXDefinition,
} from '@wix/thunderbolt-components-native';
import {
  IImageXProps,
  IImageXStateValues,
  ObjectFit,
  ResponsiveImageInfo,
} from '../ImageX.types';
import {
  getResponsiveImageObjectFit,
  getResponsiveImageSources,
  hasValidUnits,
} from './ImageX.utils';

export const props = withCompInfo<
  ImageXProps,
  ImageXDefinition,
  IImageXProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
    'editorName',
    'experiments',
  ],
  ({ editorName, experiments, ...compPropsRest }, carmiProps: IImageXProps) => {
    const {
      link: _link,
      imageInfo: { alignType, imageData },
      responsiveImagesInfo,
      shouldUseResponsiveImages,
      hasScrollEffects,
    } = carmiProps;

    const allowWEBPTransform: any =
      experiments['specs.thunderbolt.allowWEBPTransformation'] === true;

    const lazyLoadImgExperimentOpen =
      experiments['specs.thunderbolt.lazyLoadImages'] === true;

    const useNewImageParallax: any =
      experiments['specs.thunderbolt.useNewImageParallax'] === true;

    const link = _link && Object.keys(_link).length ? _link : undefined;
    const { width, height } = imageData.crop ? imageData.crop : imageData;
    const aspectRatio = height / width;

    const shouldSkipResponsiveImages =
      !responsiveImagesInfo ||
      !shouldUseResponsiveImages ||
      responsiveImagesInfo?.some(
        (data: ResponsiveImageInfo) =>
          !hasValidUnits(data.layout) || hasScrollEffects,
      );

    const sources = !shouldSkipResponsiveImages
      ? (getResponsiveImageSources(
          responsiveImagesInfo,
          imageData,
          alignType,
        ) as IImageXProps['sources'])
      : [];

    const objectFit: ObjectFit =
      !shouldSkipResponsiveImages && sources?.length > 0
        ? getResponsiveImageObjectFit(
            sources,
            responsiveImagesInfo,
            imageData,
            alignType,
          )
        : 'cover';

    const sourcesWithSrcset = sources.filter(s => !!s?.srcset);
    const filteredSources = sourcesWithSrcset
      .filter(
        (s, index: number) =>
          index === 0 || s?.srcset !== sourcesWithSrcset[index - 1]?.srcset,
      )
      .reverse();

    return {
      ...compPropsRest,
      ...carmiProps,
      link,
      sources: filteredSources,
      responsiveImagesInfo,
      objectFit,
      aspectRatio,
      useNativeAspectRatio: editorName === 'Studio',
      allowWEBPTransform,
      lazyLoadImgExperimentOpen,
      ...(useNewImageParallax && { hasScrollEffects: false }),
    };
  },
);

const stateRefs = withStateRefsValues<keyof IImageXStateValues>(['reportBi']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
