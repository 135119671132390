import React from 'react';
import classnames from 'clsx';
import { cloneDeep, set } from 'lodash';
import ErrorOnStage from '../OnStageError/OnStageError';
import EmptyState from '../EditorEmptyState/EditorEmptyState';
import { isEmptyFileParams } from '../../../editor/panels/ExternalComponent.panels.utils';
import { ExternalComponentErrorType } from '../../../ExternalComponent.types';
import { ExternalComponentPreviewProps } from './PreviewExternalComponent.types';
import { classes } from './PreviewExternalComponent.st.css';

const PreviewExternalComponent: React.FunctionComponent<
  ExternalComponentPreviewProps
> = props => {
  const {
    id,
    url,
    importKey,
    Component,
    compProps,
    events,
    handleEvents,
    error,
    className = '',
    translations,
    isLoading = false,
  } = props;

  const newCompProps = React.useMemo(() => {
    if (!handleEvents || !events || events.length === 0) {
      return compProps;
    }

    const processedProps = cloneDeep(compProps);

    for (const path of events) {
      set(processedProps, path, (...args: any) =>
        handleEvents(JSON.stringify(path), args),
      );
    }

    return processedProps;
  }, [events, handleEvents, compProps]);

  if (isEmptyFileParams({ url, importKey })) {
    return (
      <div id={id} className={className} data-byoc-root>
        <EmptyState translations={translations} />
      </div>
    );
  }

  if (Component) {
    return (
      <div id={id} className={className} data-byoc-root>
        <Component compProps={newCompProps}></Component>
      </div>
    );
  }

  let _error = error;

  if (isLoading) {
    _error = { name: ExternalComponentErrorType.Reloading, message: '' };
  }

  return (
    <div id={id} className={classnames(className, classes.root)} data-byoc-root>
      <ErrorOnStage error={_error} translations={translations} />
    </div>
  );
};

export default PreviewExternalComponent;
