import { Direction } from '../../../common/constants';
import { TabsAlignment, TabsMenuMode } from '../Tabs.types';

type JustifyContentValue = 'start' | 'end' | 'normal' | 'center';
type FlexDirection = 'row' | 'column' | 'row-reverse';
type MarginCSSValue = 0 | 'auto';

type Overflows = {
  '--tabs-list-overflow-x': 'visible' | 'auto';
  '--tabs-list-overflow-y': 'visible' | 'auto';
};

type MenuModeCSSVars = Overflows & {
  '--tabs-list-justify-content': JustifyContentValue;
  '--tabs-list-flex-wrap': 'wrap' | 'nowrap';
  '--tabs-list-scroll-buttons-display': 'flex' | 'none';
};

type TabsListAlignmentCssVars = {
  '--tabs-list-first-child-margin-start': MarginCSSValue;
  '--tabs-list-last-child-margin-end': MarginCSSValue;
};

type FlexDirectionVars = {
  '--tabs-container-flex-direction': FlexDirection;
  '--tabs-list-flex-direction': FlexDirection;
};

export const sumArgs = (...args: Array<string | number | undefined>): number =>
  args.reduce((acc: number, val) => (val === undefined ? acc : acc + +val), 0);

export const toPxValue = (val: string | number | undefined) =>
  val === undefined ? '0px' : `${val}px`;

export const getFlexDirectionVars = (isHorizontal: boolean, isRtl: boolean) => {
  return (
    isHorizontal
      ? {
          '--tabs-container-flex-direction': 'column',
          '--tabs-list-flex-direction': 'row',
        }
      : {
          '--tabs-container-flex-direction': isRtl ? 'row-reverse' : 'row',
          '--tabs-list-flex-direction': 'column',
        }
  ) as FlexDirectionVars;
};

const getJustifyContentForHorizontalWrap = (
  itemsAlignment: TabsAlignment,
  isRtl: boolean,
): JustifyContentValue => {
  if (itemsAlignment === 'center') {
    return 'center';
  } else if (
    (itemsAlignment === 'left' && !isRtl) ||
    (itemsAlignment === 'right' && isRtl)
  ) {
    return 'start';
  } else {
    return 'end';
  }
};

export const getMenuModeCSSVars = (
  menuMode: TabsMenuMode,
  isHorizontal: boolean,
  itemsAlignment: TabsAlignment,
  isRtl: boolean,
): MenuModeCSSVars => {
  if (!isHorizontal) {
    return {
      '--tabs-list-overflow-x': 'visible',
      '--tabs-list-overflow-y': 'auto',
      '--tabs-list-flex-wrap': 'nowrap',
      '--tabs-list-justify-content': 'normal',
      '--tabs-list-scroll-buttons-display': 'flex',
    };
  } else if (menuMode === 'scroll') {
    return {
      '--tabs-list-overflow-x': 'auto',
      '--tabs-list-overflow-y': 'visible',
      '--tabs-list-flex-wrap': 'nowrap',
      '--tabs-list-justify-content': 'normal',
      '--tabs-list-scroll-buttons-display': 'flex',
    };
  } else {
    const justifyContent = getJustifyContentForHorizontalWrap(
      itemsAlignment,
      isRtl,
    );
    return {
      '--tabs-list-overflow-x': 'visible',
      '--tabs-list-overflow-y': 'visible',
      '--tabs-list-flex-wrap': 'wrap',
      '--tabs-list-justify-content': justifyContent,
      '--tabs-list-scroll-buttons-display': 'none',
    };
  }
};

export const getTabsListAlignmentCssVars = (
  menuMode: TabsMenuMode,
  isHorizontal: boolean,
  itemsAlignment: TabsAlignment,
  isRtl: boolean,
): TabsListAlignmentCssVars => {
  let firstChildMarginStart: MarginCSSValue = 0;
  let lastChildMarginEnd: MarginCSSValue = 0;

  if (isHorizontal && menuMode === 'scroll') {
    if (itemsAlignment === 'center') {
      firstChildMarginStart = 'auto';
      lastChildMarginEnd = 'auto';
    } else if (
      (itemsAlignment === 'left' && isRtl) ||
      (itemsAlignment === 'right' && !isRtl)
    ) {
      firstChildMarginStart = 'auto';
    }
  }

  return {
    '--tabs-list-first-child-margin-start': firstChildMarginStart,
    '--tabs-list-last-child-margin-end': lastChildMarginEnd,
  };
};

export const getTabsListDirectionCSSVars = (
  itemsDirection: Direction,
  isHorizontal: boolean,
) => {
  const rtl = itemsDirection === 'rtl';

  return {
    ...(isHorizontal
      ? {
          '--tabs-list-back-button-inline-end': 'auto',
          '--tabs-list-back-button-block-end': 0,
          '--tabs-list-forward-button-inline-start': 'auto',
          '--tabs-list-forward-button-block-start': 0,
          '--tabs-list-scroll-icons-transform': 'rotate(0)',
        }
      : {
          '--tabs-list-back-button-inline-end': 0,
          '--tabs-list-back-button-block-end': 'auto',
          '--tabs-list-forward-button-inline-start': 0,
          '--tabs-list-forward-button-block-start': 'auto',
          '--tabs-list-scroll-icons-transform': 'rotate(90deg)',
        }),
    '--tabs-list-direction': itemsDirection,
    '--tabs-list-scroll-buttons-transform':
      isHorizontal && rtl ? 'scaleX(-1)' : 'scaleX(1)', // Flip buttons if horizontal rtl
  };
};
