import React from 'react';
import {
  activateBySpaceOrEnterButton,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { IPayPalButtonProps } from '../PayPalButton.types';
import { CREATE_ORDER_URL, TestIds } from '../constants';
import style from './style/PayPalButton.scss';
import PayDialog from './Components/PayDialog';
import ErrorDialog from './Components/ErrorDialog';
import { getItemName, isPayableAmount } from './utils';

const PayPalButton: React.FC<IPayPalButtonProps> = props => {
  const {
    id,
    className,
    amount,
    locale,
    currencyCode,
    itemNumber,
    business,
    buttonType,
    formTarget,
    submitImageAriaLabel,
    submitImageSrc,
    trackingPixelImgSrc,
    metaSiteInstance,
    isMobileView,
    metaSiteId,
    userId,
    direction,
    translations,
    getVisitorId,
    onMouseEnter,
    onMouseLeave,
    reportBiOnClick,
  } = props;

  const translate = (
    key: string,
    fallback: string = '',
    vars: Record<string, string> = {},
  ) => {
    let translation = translations[key] || fallback;
    Object.keys(vars).forEach(
      variable =>
        (translation = translation.replace(
          `<%=${variable}%>`,
          `${vars[variable]}`,
        )),
    );
    return translation;
  };

  const itemName = getItemName(props.itemName, buttonType, translate);

  const [showPayDialog, setShowPayDialog] = React.useState(false);
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  const onPayClick = (finalAmount: string, finalItemName: string) => {
    let newWindow = null;
    if (formTarget === '_blank') {
      newWindow = window.open();
    }
    void handleCreateOrder(finalAmount, finalItemName, newWindow);
  };

  const handleCreateOrder = async (
    finalAmount: string,
    finalItemName: string,
    newWindow: Window | null,
  ) => {
    try {
      const orderInfo = {
        amount: Number(finalAmount).toFixed(2),
        itemName: finalItemName,
        itemNumber,
        locale,
        currencyCode,
        business,
        returnUrl: window.location.href,
        visotorId: getVisitorId(),
        metaSiteId,
        userId,
      };
      const createOrderResponse = await fetch(CREATE_ORDER_URL, {
        method: 'POST',
        body: JSON.stringify(orderInfo),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: metaSiteInstance,
        },
      });
      const resText = await createOrderResponse.text();
      const resData = JSON.parse(resText);
      const redirectUrl =
        resData?.links?.find((link: any) => link.rel === 'approve')?.href || '';
      redirectToPayPal(redirectUrl, newWindow);
    } catch (err) {
      setShowErrorDialog(true);
      newWindow?.close();
    }
  };

  const onPayPalButtonClick = (
    event: React.MouseEvent<Element, MouseEvent>,
  ) => {
    reportBiOnClick?.(event);

    if (!business || (!isPayableAmount(amount, currencyCode) && amount)) {
      setShowErrorDialog(true);
    } else {
      setShowPayDialog(true);
    }
  };

  const redirectToPayPal = (url: string, newWindow: Window | null) => {
    if (newWindow) {
      newWindow.location = url;
      return;
    }
    return window.open(url, formTarget);
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={className}
      style={{ height: 'auto' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <PayDialog
        onPayClick={onPayClick}
        isOpen={showPayDialog}
        onClose={() => setShowPayDialog(false)}
        business={business}
        originalItemName={itemName}
        originalAmount={amount}
        currencyCode={currencyCode}
        translate={translate}
        buttonType={buttonType}
        isMobileView={isMobileView}
        direction={direction}
      />
      <ErrorDialog
        isOpen={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        translate={translate}
        isMobileView={isMobileView}
        direction={direction}
      />
      <img
        alt="PayPal Button"
        className={style.submitImage}
        role="button"
        src={submitImageSrc}
        tabIndex={0}
        aria-label={submitImageAriaLabel}
        data-testid={TestIds.submitImg}
        onClick={onPayPalButtonClick}
        onKeyDown={activateBySpaceOrEnterButton}
      />
      <img
        alt="PayPal Button"
        className={style.trackingPixel}
        width="1"
        height="1"
        role="none"
        src={trackingPixelImgSrc}
        data-testid={TestIds.trackingPixel}
      />
    </div>
  );
};

export default PayPalButton;
