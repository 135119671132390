import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  generatePreviewStateDataMap,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import {
  CollapsibleTextDefinition,
  ICollapsibleTextMapperProps,
} from '../CollapsibleText.types';
import { props as viewerPropsMapper } from './CollapsibleText.mapper';

const useSimplePreviewStateSpec =
  'specs.thunderbolt.CollapsibleTextNewPreviewStateImplementation';

const props = composeMapper<
  PreviewWrapperProps<ICollapsibleTextMapperProps>,
  CollapsibleTextDefinition
>()(
  viewerPropsMapper,
  {
    remove: [
      'compId',
      'fullNameCompType',
      'language',
      'mainPageId',
      'trackClicksAnalytics',
    ],
    add: ['compPreviewState', 'experiments'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState, experiments } = deps;
    const shouldUseSimplePreviewState = isExperimentOpen(
      experiments,
      useSimplePreviewStateSpec,
    );

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: { compPreviewState, shouldUseSimplePreviewState },
      ...(shouldUseSimplePreviewState
        ? { previewStateMap: generatePreviewStateDataMap(compPreviewState) }
        : {}),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
