import { formatClassNames } from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import * as React from 'react';

import { DataFetcherDirection, TestId } from '../constants';
import semanticClassNames from '../Grid.semanticClassNames';
import { GridDataFetcherDirection } from '../Grid.types';
import { PaginationArrow } from './PagginationArrow';
import styles from './styles/Grid.scss';

type IDirectionalPaginationProps = {
  onChangePage(direction: GridDataFetcherDirection): void;
  nextEnabled?: boolean;
  previousEnabled?: boolean;
};

const DirectionalPagination: React.FC<IDirectionalPaginationProps> = ({
  onChangePage,
  nextEnabled,
  previousEnabled,
}) => {
  const handleNextClick = () =>
    nextEnabled && onChangePage(DataFetcherDirection.Next);
  const handlePrevClick = () =>
    previousEnabled && onChangePage(DataFetcherDirection.Previous);

  return (
    <div
      className={classNames(
        styles.pagination,
        formatClassNames(semanticClassNames.pagination),
      )}
      data-testid={TestId.Pagination}
    >
      <button
        className={styles.paginationButton}
        onClick={handlePrevClick}
        disabled={!previousEnabled}
        aria-label="Previous"
        data-testid={TestId.PagePrev}
      >
        <PaginationArrow />
      </button>
      <button
        className={styles.paginationButton}
        onClick={handleNextClick}
        disabled={!nextEnabled}
        aria-label="Next"
        data-testid={TestId.PageNext}
      >
        <PaginationArrow isNext />
      </button>
    </div>
  );
};

export default DirectionalPagination;
