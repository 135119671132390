import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  CompDefinition,
  CompInfo,
} from '@wix/editor-elements-types/thunderbolt';
import {
  Direction,
  WithInherit,
  getScaledFont,
} from '@wix/editor-elements-common-utils';
import { BaseDataItem } from '@wix/editor-elements-definitions';
import { AbsoluteLayout } from '@wix/document-services-types';
import type {
  ICheckboxMapperProps,
  CheckboxDefinition,
  ICheckboxStateValues,
  CheckboxCSSVars,
  ICheckboxCarmiProps,
  CheckboxData,
  CheckboxProperties,
} from '../Checkbox.types';

export const props = withCompInfo<
  ICheckboxMapperProps,
  CheckboxDefinition,
  ICheckboxCarmiProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

export const generateCheckboxCSSVars = <
  Data extends Pick<CheckboxData, 'label'> & BaseDataItem,
  Properties extends Pick<
    CheckboxProperties,
    'alignment' | 'buttonSize' | 'spacing' | 'inputMobileFontSize'
  > &
    BaseDataItem,
>(
  compInfo: Pick<
    CompInfo<CompDefinition<any, Data, Properties, any>>,
    | 'compLayout'
    | 'styleProperties'
    | 'compProps'
    | 'compData'
    | 'hasResponsiveLayout'
    | 'siteFonts'
    | 'isMobileView'
    | 'siteFontsSpxResolved'
  >,
  calculatedDependencies: {
    size: number;
    direction: WithInherit<Direction>;
    labelMarginStart: string;
  },
): CheckboxCSSVars => {
  const { compProps, hasResponsiveLayout } = compInfo;
  const { inputMobileFontSize } = compProps;
  const { size, direction, labelMarginStart } = calculatedDependencies;

  const checkboxSize = `${size}px`;
  const fnt = getScaledFont(compInfo, 'fnt', inputMobileFontSize);

  return {
    '--direction': direction,
    '--checkboxSize': checkboxSize,
    '--fnt': fnt,
    '--checkmarkMargin_start': `-${size}px`,
    '--labelMargin_start': labelMarginStart,
    ...(!hasResponsiveLayout && {
      height: 'auto',
    }),
  };
};

type Overrides = {
  compLayout: AbsoluteLayout;
};

export const css = withCompInfo<
  CheckboxCSSVars,
  CheckboxDefinition,
  never,
  Overrides
>()(
  [
    'compProps',
    'compData',
    'compLayout',
    'hasResponsiveLayout',
    'structure',
    'styleProperties',
    'siteFonts',
    'isMobileView',
  ],
  compInfo => {
    const { hasResponsiveLayout, compProps, compLayout, compData } = compInfo;
    const { buttonSize, alignment, spacing } = compProps;
    const size =
      hasResponsiveLayout || compData.label ? buttonSize : compLayout.height;
    return generateCheckboxCSSVars(compInfo, {
      size,
      direction: alignment === 'right' ? 'rtl' : 'ltr',
      labelMarginStart: compData.label ? `${spacing + 1}px` : 'auto',
    });
  },
);

const stateRefs = withStateRefsValues<keyof ICheckboxStateValues>(['reportBi']);

export default createComponentMapperModel({ props, css, stateRefs });
