export const componentNameKey = 'CookieConsent_Dataset_Component_VideoPlayer';
export const componentNameDefault = 'Video Player';

export const ARIA_LABEL_NAMESPACE = 'ariaLabels';
export const VIDEOPLAYER_PLAY_ARIA_LABEL_KEY =
  'VideoPlayer_AriaLabel_Play_Video';
export const VIDEOPLAYER_PLAY_ARIA_LABEL_DEFAULT = 'Play video';

export const facebookDimensionsExperiment =
  'specs.thunderbolt.facebookVideoPlayerDimensions';
