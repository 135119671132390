import * as React from 'react';
import { WixRicosViewer } from '@wix/ricos-viewer';

import { pluginEmoji } from 'wix-rich-content-plugin-emoji/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/viewer';
import { pluginSpoiler } from 'wix-rich-content-plugin-spoiler/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginLinkButton } from 'wix-rich-content-plugin-button/viewer';

import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/viewer';
import { pluginTable } from 'wix-rich-content-plugin-table/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/viewer';
import { pluginAudio } from 'wix-rich-content-plugin-audio/viewer';

import { formatClassNames } from '@wix/editor-elements-common-utils';
import { DefaultCompPlatformProps } from '@wix/editor-elements-types/src/thunderboltExternal';
import type { IRichContentViewerProps } from '../RichContentViewer.types';

import semanticClassNames from '../RichContentViewer.semanticClassNames';
import { styleParamDefaults } from '../styleParamDefaults';
import { st, classes } from './style/RichContentViewer.component.st.css';
import { RichContentViewerTrimContainer } from './RichContentViewerTrimContainer';

const RichContentViewer: React.FC<
  DefaultCompPlatformProps & IRichContentViewerProps
> = props => {
  const {
    id,
    customClassNames = [],
    className,
    content,
    previewContent,
    metaSiteId,
    isMobileView,
    onNodeClick,
    language,
  } = props;

  const contentResolved = content ?? previewContent;
  if (!contentResolved) {
    return null;
  }

  return (
    <div id={id} className={className} style={{ height: 'auto' }}>
      <div
        className={st(
          classes.root,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        style={{
          backgroundColor: `rgb(var(--bgColor, var(--${styleParamDefaults.background.color})), var(--alpha-bgColor, ${styleParamDefaults.background.alpha}))`,
          borderRadius: 'var(--borderRadius, 0)',
          border: `calc(1px * var(--borderWidth, 0)) solid rgb(var(--borderColor, var(--${styleParamDefaults.border.color})), var(--alpha-borderColor, ${styleParamDefaults.border.alpha}))`,
          padding: `calc(1px * var(--verticalPadding, ${styleParamDefaults.padding.vertical})) calc(1px * var(--horizontalPadding, ${styleParamDefaults.padding.horizontal}))`,
        }}
      >
        <RichContentViewerTrimContainer
          options={props}
          content={contentResolved}
          ellipsisStyle={{
            ...getTextThemeForTag('p', isMobileView),
            color: `rgba(${actionColor})`,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: '1em',
          }}
        >
          {contentTrimmed => (
            <WixRicosViewer
              content={contentTrimmed}
              locale={language}
              biSettings={{
                consumer: 'editor-element',
                platform: 'Livesite',
                postId: '',
                containerId: '',
              }}
              msid={metaSiteId}
              instance=""
              theme={{
                parentClass: classes.root,
                customStyles: {
                  p: getTextThemeForTag('p', isMobileView),
                  h1: getTextThemeForTag('h1', isMobileView),
                  h2: getTextThemeForTag('h2', isMobileView),
                  h3: getTextThemeForTag('h3', isMobileView),
                  h4: getTextThemeForTag('h4', isMobileView),
                  h5: getTextThemeForTag('h5', isMobileView),
                  h6: getTextThemeForTag('h6', isMobileView),
                  quote: getTextThemeForTag('quote', isMobileView),
                },
                palette: {
                  type: 'rgb',
                  textColor: 'var(--color_15)',
                  actionColor,
                  textColorLow: 'var(--color_14)',
                  disabledColor: 'var(--color_13)',
                  bgColor: 'var(--bgColor)',
                },
              }}
              plugins={[
                pluginCollapsibleList(),
                pluginDivider(),
                pluginEmoji(),
                pluginGiphy(),
                pluginHashtag(),
                pluginImage(),
                pluginGallery(),
                pluginVideo(),
                pluginAudio(),
                pluginFileUpload(),
                pluginLink(),
                pluginMentions(),
                pluginTable(),
                pluginTextColor(),
                pluginTextHighlight(),
                pluginHtml(),
                pluginLinkPreview(),
                pluginVerticalEmbed(),
                pluginSpoiler(),
                pluginPoll(),
                pluginLinkButton(),
              ]}
              mediaSettings={{
                fullscreenProps: {
                  container() {
                    return document.querySelector('.site-root');
                  },
                },
              }}
              onNodeClick={onNodeClick}
            />
          )}
        </RichContentViewerTrimContainer>
      </div>
    </div>
  );
};

function getTextThemeForTag(
  tag: keyof typeof styleParamDefaults.font,
  isMobileView: boolean,
) {
  return {
    color: `rgb(var(--${tag}Color, ${styleParamDefaults.fontColor}))`,
    fontStyle: `var(--${tag}FontStyle, normal)`,
    fontWeight: `var(--${tag}FontWeight, normal)`,
    fontSize: `calc(1px * var(--${tag}FontSize, ${
      isMobileView
        ? styleParamDefaults.font[tag].mobileSize
        : styleParamDefaults.font[tag].size
    }))`,
    lineHeight: '1.5em',
    fontFamily: `var(--${tag}FontFamily, var(--${styleParamDefaults.font[tag].familyFrom}-family))`,
    textDecoration: `var(--${tag}TextDecoration, none)`,
  };
}

const actionColor = 'var(--linkColor, var(--color_18))';

export default RichContentViewer;
