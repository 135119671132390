import { ViewerTranslations } from '@wix/editor-elements-types/src/components';
import {
  BusinessLogFn,
  IMode,
  ISocialVendors,
  IdpConnection,
} from '../MemberLoginDialog.types';

export type SiteMembersTranslations = ViewerTranslations<string>;

export const INVISIBLE_CAPTCHA_SITE_KEY =
  '6LdoPaUfAAAAAJphvHoUoOob7mx0KDlXyXlgrx5v';
export const translationFeature = 'dialogMixinTranslations';
export const PASS_MIN_LEN = 4;
export const PASS_MAX_LEN = 100;
export const IDP_APPDEFIDS: Record<ISocialVendors, string> = {
  facebook: '3ecad13f-52c3-483d-911f-31dbcf2a6d23',
  google: '0e6a50f5-b523-4e29-990d-f37fa2ffdd69',
  sso: '4f199cbe-22ee-40c8-9129-11f2b4178a38',
};
export const IGNORED_ERRORS = ['SOCIAL_LOGIN_CANCELLED_BY_USER'];

export const testIds = {
  socialAuth: 'socialAuth',
  emailAuth: 'emailAuth',
  socialAuthMessage: 'socialAuthMessage',
  copyLinkButton: 'copyLinkButton',
  switchToSignUp: 'switchToSignUp',
  switchToSignUpDescription: 'switchToSignUpDescription',
  forgotPasswordMobile: 'forgotPasswordMobile',
  forgotPasswordDesktop: 'forgotPasswordDesktop',
  switchToEmailLink: 'switchToEmailLink',
  submit: 'submit',
  googleMain: 'googlenew',
  googleSmall: 'googlenew-small',
  facebookMain: 'fbnew',
  facebookSmall: 'fbnew-small',
};

export const MemberLoginDialogTranslationKeys = {
  title: 'membersLoginDialogTitle',
  submitButton: 'membersLoginDialogSubmitButton',
  switchDialogLink: 'membersLoginDialogSwitchDialogLink',
  mobileForgotPassword: 'membersLoginDialogMobileForgotPassword',
  forgotPassword: 'membersLoginDialogForgotPassword',
  switchToSignUp: 'membersLoginDialogSwitchToSignUp',
  socialSectionDivider: 'membersLoginDialogSocialSectionDivider',
  emailSectionDivider: 'membersLoginDialogEmailSectionDivider',
  inAppBrowserSocialAuth: (
    authMethod: 'Signup' | 'Login',
    socialMethod: 'Google' | 'Facebook' | 'GoogleFacebook',
  ) => {
    // e.g- To Login with Google or Facebook copy this page link and open it in another browser.
    return `membersLoginDialogCopyLink${authMethod}${socialMethod}`;
  },
  copyLinkText: 'membersLoginDialogCopyLinkText',
  password: {
    title: 'membersLoginDialogPasswordFieldTitle',
    label: 'membersLoginDialogPasswordFieldLabel',
  },
  email: {
    title: 'membersLoginDialogEmailField',
    label: 'membersLoginDialogEmailField',
  },
  social: {
    iframeTitle: 'membersLoginDialogSocialLoginTitle',
    google: {
      login: 'membersLoginDialogLoginWithGoogle',
      signup: 'membersLoginDialogSignupWithGoogle',
    } as Record<IMode, string>,
    facebook: {
      login: 'membersLoginDialogLoginWithFacebook',
      signup: 'membersLoginDialogSignupWithFacebook',
    } as Record<IMode, string>,
    sso: {
      login: 'membersLoginDialogLoginWithSso',
      signup: 'membersLoginDialogSignupWithSso',
    },
  },
  switchToAuthWithEmail: 'membersLoginDialogSwitchToAuthWithEmail',
  linkCopied: 'membersLoginDialogLinkCopied',
  ssoTitle: 'dialogMixinTranslations_sso_title',
  ssoSubtitle: 'dialogMixinTranslations_sso_subtitle',
  headlessTitle: 'dialogMixinTranslations_headless_title',
  headlesSubtitle: 'dialogMixinTranslations_headless_subtitle',
};

export const getReportCopyToClipboardBiCallback =
  (
    reportBi: BusinessLogFn,
    options: { biVisitorId: string; metaSiteId: string },
  ) =>
  () =>
    reportBi(
      {
        src: 5,
        evid: 1643,
        ...options,
      },
      { endpoint: 'site-members' },
    );

export const SM_ERROR_CODES = {
  CAPTCHA_REQUIRED: '19971',
  CAPTCHA_INVALID: '19970',
};

export function isFacebookEnabled(idps: Array<IdpConnection>): boolean {
  return idps.some(idp => idp.appDefId === IDP_APPDEFIDS.facebook);
}

export function isGoogleEnabled(idps: Array<IdpConnection>): boolean {
  return idps.some(idp => idp.appDefId === IDP_APPDEFIDS.google);
}

export function isMandatorySSO(
  idps: Array<IdpConnection>,
  isEmailLoginEnabled: boolean,
): boolean {
  return (
    !isEmailLoginEnabled &&
    idps.length === 1 &&
    idps[0].appDefId === IDP_APPDEFIDS.sso
  );
}
