import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  consentPolicyPropsDeps,
  withConsentPolicyProps,
} from '@wix/thunderbolt-elements/src/components/ConsentPolicyWrapper/viewer/utils';
import {
  GoogleMapDefinition,
  GoogleMapMapperProps,
  GoogleMapsPlatformProps,
  GoogleMapStateRefs,
} from '../GoogleMap.types';
import { consetPolicyKeys } from '../constants';
import { calculateGoogleMapLanguage } from '../utils';

export const props = withCompInfo<
  GoogleMapMapperProps,
  GoogleMapDefinition,
  GoogleMapsPlatformProps
>()(
  [
    'compId',
    'compData',
    'compProps',
    'translate',
    'language',
    ...consentPolicyPropsDeps,
  ],
  (compInfo, carmiData) => {
    const {
      compId,
      compData,
      compProps,
      translate,
      language: siteLanguage,
    } = compInfo;

    const { urlQueries, mapData, skin } = carmiData;

    const language = calculateGoogleMapLanguage(
      compData.language,
      compProps.language,
      siteLanguage,
    );

    const wrappedUrlQueries = new URLSearchParams(urlQueries);
    wrappedUrlQueries.set('language', language);

    const originalProps = {
      id: compId,
      urlQueries: wrappedUrlQueries.toString(),
      language,
      mapData: {
        /** Create new instance of mapData to rerender iFrame on data changes
         * (useGoogleIFrame depends on immutability)
         */
        ...mapData,
      },
      skin,
      translations: {
        title:
          translate('component_label', 'COMPONENT_LABEL_googleMapsTitle') ||
          'Google Map',
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

const stateRefs = withStateRefsValues<keyof GoogleMapStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
