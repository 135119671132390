import {
  formatClassNames,
  HAS_CUSTOM_FOCUS_CLASSNAME,
} from '@wix/editor-elements-common-utils';
import * as React from 'react';
import semanticClassNames from '../SelectableContainerInput.semanticClassNames';
import type { SelectableContainerInputComponentProps } from '../types';
import { dataHooks, labelPlaceholder } from '../constants';
import { useSelectableContainerContext } from '../../SelectableContainer/SelectableContainer.context';
import { classes, st } from './style/SelectableContainerInput.component.st.css';

const SelectableContainerInput: React.FC<
  SelectableContainerInputComponentProps
> = props => {
  const {
    inputName,
    inputLabel,
    inputAriaLabel,
    inputRef,
    selected,
    onFocus,
    onBlur,
  } = useSelectableContainerContext();
  const {
    id,
    stylableClassName,
    customClassNames = [],
    selectedPreview = false,
  } = props;
  const inputId = `SelectableContainerInput-${id}`;
  const isSelected = selected || selectedPreview;

  return (
    <div
      id={id}
      className={classes.wrapper}
      data-hook={dataHooks.selectableContainerInputContainer}
    >
      <div
        className={st(
          classes.root,
          {
            selected: isSelected,
          },
          stylableClassName,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
      >
        <input
          id={inputId}
          name={inputName}
          ref={inputRef}
          checked={isSelected}
          className={st(classes.input, HAS_CUSTOM_FOCUS_CLASSNAME)}
          readOnly
          data-hook={dataHooks.selectableContainerInput}
          type="radio"
          aria-label={inputAriaLabel}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div className={classes.inputWrapper}>
          <span
            className={st(
              classes.customInput,
              formatClassNames(semanticClassNames.input),
            )}
          />
          <label
            className={st(
              classes.label,
              formatClassNames(semanticClassNames.label),
            )}
            htmlFor={inputId}
          >
            {inputLabel || labelPlaceholder}
          </label>
        </div>
      </div>
    </div>
  );
};

export default SelectableContainerInput;
