import { FastGalleryImages } from '../FastGallery.types';

export const itemsConverter = (items: Array<FastGalleryImages>) => {
  return items.map(({ id, title, uri }) => {
    return {
      title,
      description: `image ${title} description`,
      uri,
      itemId: `itemId-${id}`,
    };
  });
};
