import {
  IChangePropsSDKActions,
  IClickPropSDK,
  IClickPropsSDKActions,
  IElementPropsSDK,
  IElementPropsSDKActions,
  ToJSONBase,
} from '@wix/editor-elements-corvid-utils';
import {
  CorvidSDKPropsFactory,
  CorvidTypes,
  IComponentEvent,
  ICorvidEvent,
} from '@wix/editor-elements-types/corvid';
import type {
  DefaultCompPlatformProps,
  DefaultContainerProps,
  Direction,
} from '@wix/editor-elements-types/thunderbolt';
import { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import { $W } from '@wix/thunderbolt-symbols';
import React from 'react';
import type { IComponentPanelProps } from '@wix/editor-elements-types/editor';
import { IWithPanelsBi } from '@wix/editor-elements-preview-utils/panels';
import type { PickStateRefValues } from '@wix/editor-elements-integrations';
import { StyleProperties } from '@wix/editor-elements-types/thunderbolt';
import type { ISingleTabSDK } from '../SingleTab/SingleTab.types';
import { mapStateToProps } from './editor/panels/layout/Tabs.layoutPanel';
import { TabStateProps } from './editor/panels/manage/Tabs.managePanel.mapper';

export type TabsAlignment = 'left' | 'center' | 'right';
export type TabsMenuMode = 'wrap' | 'scroll';
export type TabsOrientation = 'horizontal' | 'vertical';

export type ITabsListItem = {
  id: string; // compId
  tabId: string;
  label: string;
};

export type ITabsControllerActions = {
  setCurrentTabId: (currentTabId: string) => void;
};

export type ITabsScrollBtnProps = {
  className: string;
  dataHook?: string;
  onClick: () => void;
};

export type ITabsListItemProps = ITabsListItem & {
  isActive: boolean;
  onClick: () => void;
};

export type ITabsListProps = {
  currentTabId: ITabsMapperProps['currentTabId'];
  tabItems: Array<ITabsListItem>;
  onTabItemClick: (tabId: string, uniqueId: string) => void;
  className: string;
  activeMenuItemRef?: React.RefObject<HTMLDivElement>;
};

export type ITabsData = {
  defaultTabId: string;
  tabIds: Array<string>;
};

type TabsCommonProps = {
  itemSpacing: number;
  containerSpacing: number;
  stretchTabsToMenuWidth: boolean;
  itemsOrientation: TabsOrientation;
  itemsAlignment: TabsAlignment;
  contentAlignment: TabsAlignment;
  horizontalTabPadding: number;
  verticalTabPadding: number;
  menuMode: TabsMenuMode;
  itemsDirection: Direction;
  itemRowSpacing: number;
};

export type HorizontalTabsProperties = TabsCommonProps;

export type VerticalTabsProperties = TabsCommonProps & {
  tabWidth: number;
};

export type ITabsProperties = HorizontalTabsProperties | VerticalTabsProperties;

export const isVerticalProperties = (
  properties: ITabsProperties,
): properties is VerticalTabsProperties => {
  return properties.itemsOrientation === 'vertical';
};

export type FullTabsProperties = HorizontalTabsProperties &
  VerticalTabsProperties;

export type ITabsCarmiData = {
  tabItems: Array<ITabsListItem>;
  containerProps?: ResponsiveContainerProps;
  containerRootClassName?: string;
};

export type ITabsMapperProps = ITabsProperties &
  ITabsData &
  ITabsCarmiData & {
    currentTabId: string;
    stylableClassName: string;
    hasResponsiveLayout?: boolean;
    shouldFixResponsiveBoxContainerLayoutClassName: boolean;
  };

export type ITabControllerProps = ITabsMapperProps & ITabsControllerActions;
export type ITabsPlatformProps = DefaultCompPlatformProps & {
  onTabItemClicked?: (event: OnTabItemClickedComponentEvent) => void;
};

export type ITabsProps = ITabsMapperProps &
  ITabsPlatformProps &
  IChangePropsSDKActions &
  IClickPropsSDKActions &
  IElementPropsSDKActions &
  ITabsControllerActions & {
    ref?: React.Ref<ITabsImperativeActions>;
    className?: string;
    children?: React.ReactNode | DefaultContainerProps['children'];
    shouldRenderAllTabs: boolean;
    observeChildListChange?: (id: string, target: HTMLElement) => void;
  } & {
    compProps: ITabsProperties;
    styleProperties: StyleProperties;
  };

export type ITabsCorvidModel = CorvidTypes.ICorvidModel<ITabsSDKFactory>;

export type OnTabItemClickedComponentEvent = IComponentEvent<
  'OnTabItemClicked',
  { tabId: string }
>;
export type OnTabItemClickedCorvidEvent = ICorvidEvent<'OnTabItemClicked'>;

export interface ITabsSDKAttributes {
  tabs: Array<ISingleTabSDK>;
  currentTab: ISingleTabSDK;
  defaultTab: ISingleTabSDK;
}

export type ITabsImperativeActions = {
  setCurrentTabId: (tabId: string) => void;
};

export type ITabsOwnSDK = ITabsSDKAttributes & {
  orderTabs: (orderedTabIds: Array<string>) => Promise<void>;
  changeTab: (tabReference: string | ISingleTabSDK) => Promise<ISingleTabSDK>;
  onTabItemClicked: (
    cb: (event: OnTabItemClickedCorvidEvent, $w: $W) => void,
  ) => void;
  _setTabLabel: (tabId: string, newLabel: string) => void;
  toJSON: () => ToJSONBase & ITabsSDKAttributes;
};

export type ITabsSDK = ITabsOwnSDK & IElementPropsSDK & IClickPropSDK;

export type ITabsOwnSDKFactory = CorvidSDKPropsFactory<ITabsProps, ITabsOwnSDK>;

export type ITabsSDKFactory = CorvidSDKPropsFactory<ITabsProps, ITabsSDK>;

export type AllTabsRef = Record<string, React.RefObject<HTMLDivElement>>;

export type TabsLayoutPanelStateProps = ReturnType<typeof mapStateToProps> &
  IWithPanelsBi;

export type ITabsLayoutPanel = IComponentPanelProps<
  ITabsProperties,
  ITabsData
> &
  TabsLayoutPanelStateProps;

export type IManagePanelProps = IComponentPanelProps<
  ITabsProperties,
  ITabsData
> &
  TabStateProps &
  IManagePanelMapperProps;

export type IManagePanelMapperProps = ReturnType<typeof mapStateToProps> &
  IWithPanelsBi;

export type ITabsStateRefValues = PickStateRefValues<'observeChildListChange'>;
