import { formatClassNames } from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import * as React from 'react';
import semanticClassNames from '../../../SiteButton.semanticClassNames';
import { ISiteButtonImperativeActions } from '../../../SiteButton.types';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';

type ButtonArrowSkinProps = SkinButtonProps & {
  skinsStyle: Record<string, string>;
};

const ButtonArrowSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  ButtonArrowSkinProps
> = (
  {
    wrapperProps,
    linkProps,
    a11yProps,
    elementType,
    label,
    skinsStyle,
    onFocus,
    onBlur,
    customClassNames = [],
  },
  ref,
) => {
  return (
    <div
      {...wrapperProps}
      className={classNames(skinsStyle.root, wrapperProps.className)}
    >
      <div className={`${skinsStyle.arr} ${skinsStyle.top}`} />
      <div className={`${skinsStyle.arr} ${skinsStyle.bottom}`} />
      <div className={skinsStyle.bg} />
      <SiteButtonContent
        linkProps={linkProps}
        a11yProps={a11yProps}
        elementType={elementType}
        className={classNames(
          skinsStyle.link,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <span
          className={classNames(
            skinsStyle.label,
            formatClassNames(semanticClassNames.buttonLabel),
          )}
        >
          {label}
        </span>
      </SiteButtonContent>
    </div>
  );
};

export default React.forwardRef(ButtonArrowSkin);
