import * as React from 'react';
import {
  IIFrameConfig,
  IOnBackendSocialLogin,
} from '../MemberLoginDialog.types';
import { IGNORED_ERRORS } from './utils';
import { usePopUrl } from './usePopUrl';

export const useHeadlessPopup = (
  iframeConfig: IIFrameConfig,
  onBackendSocialLogin: IOnBackendSocialLogin,
  onError: (error: any) => void,
  callbackUrl?: string,
) => {
  const openPopUp = usePopUrl(true, callbackUrl);

  return React.useCallback(
    async (loginUrl: string) => {
      try {
        const loginResponse = await openPopUp(loginUrl);
        await onBackendSocialLogin(
          loginResponse,
          'HeadlessCallback',
          iframeConfig.isCommunityChecked,
        );
      } catch (error) {
        if (!IGNORED_ERRORS.includes(error as any)) {
          onError(error);
        }
      }
    },
    [onBackendSocialLogin, openPopUp, onError, iframeConfig],
  );
};
