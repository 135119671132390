import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { IHamburgerMenuRootProps } from '../HamburgerMenuRoot.props';
import { IHamburgerMenuRootDefinition } from '../documentManagement/HamburgerMenuRoot.definition';
import {
  props as viewerPropsMapper,
  stateRefs,
  IHamburgerMenuRootCarmiProps,
} from './HamburgerMenuRoot.mapper';

const props = composeMapper<
  IHamburgerMenuRootProps,
  IHamburgerMenuRootDefinition,
  IHamburgerMenuRootCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState', 'componentViewMode'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState, componentViewMode } = dependencies;

    return {
      ...viewerProps,
      compPreviewState:
        componentViewMode === 'editor' ? compPreviewState : null,
      shouldFocus: componentViewMode === 'preview',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  stateRefs,
});
