import React, { useState, useEffect, useCallback } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  isElmInViewport,
  getCenteredVerticalPosition,
} from '@wix/editor-elements-common-utils/src/commons/positions';
import {
  IAccordionContainerProps,
  IAccordionContainerPreviewProps,
} from '../types';
import { Selectors } from '../constants';
import {
  hasItemIdSelected,
  getSelectedId,
  isDeselected,
} from '../previewState';

type UsePreviewOpenedPayload = {
  compPreviewState: string;
  componentViewMode: 'editor' | 'preview';
  firstItemId: string;
  viewerOpened: Array<string>;
};

const usePreviewOpened = ({
  compPreviewState,
  componentViewMode,
  firstItemId,
  viewerOpened,
}: UsePreviewOpenedPayload): Array<string> => {
  const [selectedId, setSelectedId] = useState('');

  const isPreview = componentViewMode === 'preview';

  useEffect(() => {
    if (hasItemIdSelected(compPreviewState)) {
      setSelectedId(getSelectedId(compPreviewState));
    }
    if (isDeselected(compPreviewState)) {
      setSelectedId('');
    }
  }, [compPreviewState]);

  useEffect(() => {
    if (isPreview) {
      setSelectedId('');
    }
  }, [isPreview]);

  if (isPreview) {
    return viewerOpened;
  }

  if (selectedId) {
    return [selectedId];
  }

  if (
    [Selectors.accordionItemContent, Selectors.accordion].includes(
      compPreviewState,
    )
  ) {
    return [firstItemId];
  }

  return [];
};

export default (
  ViewerComponent: React.ComponentType<IAccordionContainerProps>,
) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps,
    ...viewerProps
  }: PreviewWrapperProps<
    IAccordionContainerProps,
    IAccordionContainerPreviewProps
  >) {
    const accordionItemPreviewRefs = React.useRef<
      Record<string, HTMLDivElement>
    >({});

    const opened = usePreviewOpened({
      compPreviewState: previewWrapperProps?.compPreviewState || '',
      componentViewMode: previewWrapperProps?.componentViewMode || 'editor',
      firstItemId: viewerProps.items[0].id,
      viewerOpened: viewerProps.opened,
    });

    const addAccordionItemPreviewRef = useCallback(
      (ref: HTMLDivElement, itemId: string) => {
        accordionItemPreviewRefs.current[itemId] = ref;
      },
      [],
    );

    useEffect(() => {
      if (previewWrapperProps?.componentViewMode !== 'editor') {
        return;
      }

      const [openItem] = opened;
      const openItemRef = accordionItemPreviewRefs.current[openItem];
      if (openItemRef) {
        const rect = openItemRef.getBoundingClientRect();
        const isInViewport = isElmInViewport(rect);
        const top = getCenteredVerticalPosition(rect);
        if (!isInViewport) {
          window.scrollTo({
            top,
            behavior: 'smooth',
          });
        }
      }
    }, [opened, previewWrapperProps?.componentViewMode]);
    return (
      <ViewerComponent
        {...viewerProps}
        opened={opened}
        addAccordionItemPreviewRef={addAccordionItemPreviewRef}
      />
    );
  });
