import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import MeshContainer from '@wix/thunderbolt-elements/components/MeshContainer';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import * as React from 'react';
import semanticClassNames from '../SelectableContainer.semanticClassNames';
import {
  ISelectableContainerImperativeActions,
  ISelectableContainerProps,
} from '../types';
import { SelectableContainerContextProvider } from '../SelectableContainer.context';
import { InputContent } from '../constants';
import { classes, st } from './style/SelectableContainer.component.st.css';
import { useFocusableHandle } from './hooks/useFocusableHandle';
import { useSelectVariant } from './hooks/useSelectVariant';

const SelectableContainer: React.ForwardRefRenderFunction<
  ISelectableContainerImperativeActions,
  ISelectableContainerProps
> = (props, ref) => {
  const {
    id,
    children,
    className = '',
    stylableClassName,
    customClassNames = [],
    containerRootClassName,
    containerProps,
    meshProps,
    hasResponsiveLayout,
    onSelect,
    selected,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    applySelectedVariant,
    removeSelectedVariant,
    isEditor,
    isInputVisible,
  } = props;

  const childrenToRender =
    typeof children === 'function' ? children : () => children;

  const inputRef = useFocusableHandle(ref);

  const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
    onSelect(event);
    inputRef.current?.focus();
  };

  const inputAriaLabel =
    props.inputContent === InputContent.Button || !isInputVisible
      ? props.inputLabel
      : undefined;

  useSelectVariant({
    selected,
    applySelectedVariant,
    removeSelectedVariant,
    isEditor,
    id,
  });

  return (
    <SelectableContainerContextProvider
      inputName={props.radioButtonName}
      selected={selected}
      inputLabel={props.inputLabel}
      inputAriaLabel={inputAriaLabel}
      inputRef={inputRef}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <div
        id={id}
        className={st(containerRootClassName, className, classes.wrapper)}
        {...getDataAttributes(props)}
        onClick={handleSelect}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={st(
            classes.root,
            { selected },
            stylableClassName,
            formatClassNames(semanticClassNames.root, ...customClassNames),
          )}
        />
        {hasResponsiveLayout ? (
          <ResponsiveContainer
            containerLayoutClassName={`${id}-container`}
            overlowWrapperClassName={`${id}-overflow-container`}
            hasOverflow={false}
            {...containerProps}
          >
            {children}
          </ResponsiveContainer>
        ) : (
          <MeshContainer {...meshProps} id={id}>
            {childrenToRender}
          </MeshContainer>
        )}
      </div>
    </SelectableContainerContextProvider>
  );
};

export default React.forwardRef(SelectableContainer);
