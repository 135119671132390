import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import SiteMembersDialogLayout from '@wix/thunderbolt-elements/src/components/SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { INoPermissionsToPageDialogProps } from '../NoPermissionsToPageDialog.types';
import { NoPermissionsToPageTranslations, TestIds } from '../constants';
import style from './style/NoPermissionsToPageDialog.scss';

const NoPermissionsToPageDialog: React.FC<
  INoPermissionsToPageDialogProps
> = props => {
  const {
    id,
    onCloseDialogCallback,
    onSwitchAccountLinkClick,
    translations,
    className,
  } = props;

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      isCloseable={false}
      onCloseDialogCallback={onCloseDialogCallback}
      className={className}
      translations={translations}
    >
      <div id={id} className={style.noPermissionsToPageDialog}>
        <h3 className={style.title}>
          {translations[NoPermissionsToPageTranslations.TITLE]}
        </h3>
        <div className={style.wrapper}>
          <div className={style.footer}>
            <div className={style.actionButton}>
              <BasicButton
                id={`${TestIds.backToSiteButton}_${id}`}
                label={
                  translations[
                    NoPermissionsToPageTranslations.BACK_TO_SITE_BTN
                  ]!
                }
                autoFocus={true}
                isDisabled={false}
                link={undefined}
                hasPlatformClickHandler={true}
                onClick={onCloseDialogCallback}
              />
            </div>
            <button
              id={`${TestIds.switchAccountLinkBtn}_${id}`}
              role="link"
              className={style.switchAccountLink}
              onClick={onSwitchAccountLinkClick}
            >
              {
                translations[
                  NoPermissionsToPageTranslations.SWITCH_ACCOUNT_LINK
                ]
              }
            </button>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default NoPermissionsToPageDialog;
