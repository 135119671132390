import React, { useMemo } from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import Image from '@wix/thunderbolt-elements/src/components/Image/viewer/Image';
import type { IFastGalleryProps } from '../FastGallery.props';
import semanticClassNames from '../FastGallery.semanticClassNames';
import { itemsConverter } from './FastGallery.galleryUtils';
import s from './style/FastGallery.scss';

const FastGallery: React.FC<IFastGalleryProps> = props => {
  const { id, customClassNames = [], wixSDKitems, items } = props;
  // eslint-disable-next-line no-console
  console.log('RENDER FastGallery');

  const galleryItems = useMemo(
    () => wixSDKitems || itemsConverter(items),
    [wixSDKitems, items],
  );
  const imageHeight = 400;
  const imageWidth = 400;

  return (
    <div
      id={id}
      className={classNames(
        'root',
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
    >
      <div
        className={classNames(
          'container',
          formatClassNames(semanticClassNames.container, ...customClassNames),
        )}
      >
        {galleryItems?.map(image => {
          return (
            <div
              key={`{${image.itemId}`}
              className={classNames(
                'item',
                formatClassNames(semanticClassNames.item, ...customClassNames),
              )}
            >
              <Image
                key={image.itemId}
                id={image.itemId}
                className={s.image}
                uri={image.uri}
                width={imageHeight}
                height={imageWidth}
                displayMode="fill"
                alt={image?.alt || ''}
                containerHeight={imageHeight}
                containerWidth={imageWidth}
                name=""
                skipMeasure
              />
              {/* <div className={s.title}>{image.title}</div>
              <div className={s.description}>{image.description}</div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FastGallery;
