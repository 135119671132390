import * as React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import SiteButtonContent from '../../SiteButtonContent';
import { SkinButtonProps } from '../SkinButton.types';
import SiteButton from '../../SiteButton';
import {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import semanticClassNames from '../../../SiteButton.semanticClassNames';
import skinsStyle from './styles/skins.scss';

const ButtonDoubleArrowLeftSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>(
  (
    {
      wrapperProps,
      linkProps,
      a11yProps,
      elementType,
      label,
      onFocus,
      onBlur,
      customClassNames = [],
    },
    ref,
  ) => {
    return (
      <div
        {...wrapperProps}
        className={classNames(
          skinsStyle.ButtonDoubleArrowLeft,
          wrapperProps.className,
        )}
      >
        <div className={skinsStyle.wrapper}>
          <div className={`${skinsStyle.container} ${skinsStyle.left}`}>
            <div className={skinsStyle.ribbon} />
          </div>
          <div
            className={`${skinsStyle.container} ${skinsStyle.top} ${skinsStyle.right}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div
            className={`${skinsStyle.container} ${skinsStyle.bottom} ${skinsStyle.right}`}
          >
            <div className={skinsStyle.ribbon} />
          </div>
          <div className={skinsStyle.filler} />
        </div>
        <SiteButtonContent
          linkProps={linkProps}
          a11yProps={a11yProps}
          elementType={elementType}
          className={classNames(
            skinsStyle.link,
            formatClassNames(semanticClassNames.root, ...customClassNames),
          )}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={ref}
        >
          <span
            className={classNames(
              skinsStyle.label,
              formatClassNames(semanticClassNames.buttonLabel),
            )}
          >
            {label}
          </span>
        </SiteButtonContent>
      </div>
    );
  },
);

const ButtonDoubleArrowLeft = React.forwardRef<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
>((props, ref) => (
  <SiteButton {...props} skin={ButtonDoubleArrowLeftSkin} ref={ref} />
));

export default ButtonDoubleArrowLeft;
