import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getScaledFont,
  getBrightnessCSSVars,
  isSupportSpExperimentOn,
  LogicalAlignment,
  PhysicalAlignment,
  migrateFields,
  supportSpSpec,
  convertPhysicalAlignmentToLogical,
} from '@wix/editor-elements-common-utils';
import {
  SiteButtonDefinition,
  ISiteButtonProps,
  SiteButtonCSSVars,
  SiteButtonStateValues,
} from '../SiteButton.types';
import SloopyButtonStyle from './skinComps/SloopyButton/styles/skins.scss';

export const props = withCompInfo<
  ISiteButtonProps,
  SiteButtonDefinition,
  ISiteButtonProps
>()(
  [
    'isQaMode',
    'fullNameCompType',
    'language',
    'mainPageId',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

export const css = withCompInfo<SiteButtonCSSVars, SiteButtonDefinition>()(
  [
    'compData',
    'styleProperties',
    'compProps',
    'compLayout',
    'siteColors',
    'siteFonts',
    'skin',
    'isMobileView',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
    'isOneDocMigrated',
  ],
  ({
    compData,
    styleProperties,
    compProps,
    compLayout,
    siteColors,
    siteFonts,
    skin,
    isMobileView,
    isOneDocMigrated,
    formatCssValue,
    experiments,
    siteFontsSpxResolved,
  }) => {
    const isSupportSpOn = isSupportSpExperimentOn(experiments);
    const { direction } = compData;
    const { align, margin } = styleProperties;

    const newAlign: LogicalAlignment =
      convertPhysicalAlignmentToLogical(align as PhysicalAlignment) ?? align;

    const { spx } = styleProperties;
    const defaultMargin =
      skin === 'SloopyButton' ? SloopyButtonStyle.defaultMargin : '0px';
    const labelMargin =
      newAlign !== 'center'
        ? {
            [`--margin-${newAlign}`]: isSupportSpOn
              ? formatCssValue(margin, spx)
              : `${margin}px`,
          }
        : { '--margin-start': defaultMargin, '--margin-end': defaultMargin };

    const brightnessParams =
      skin === 'ScotchTapeButton'
        ? {}
        : getBrightnessCSSVars({ siteColors, styleProperties });

    return {
      ...brightnessParams,
      ...labelMargin,
      '--fnt': getScaledFont(
        {
          styleProperties,
          compLayout: isOneDocMigrated ? {} : compLayout,
          compProps,
          isMobileView,
          siteFonts,
          siteFontsSpxResolved: isSupportSpOn
            ? siteFontsSpxResolved
            : undefined,
        },
        'fnt',
      ),
      direction,
      '--label-align': newAlign,
      '--label-text-align': align,
    };
  },
  [
    migrateFields([
      {
        experiment: supportSpSpec,
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: ['margin', 'align'],
      },
    ]),
  ],
);

const stateRefs = withStateRefsValues<keyof SiteButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
