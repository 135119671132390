import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
  StylableButtonSdkData,
  IStylableButtonStateValues,
} from '../StylableButton.types';

export const props = withCompInfo<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  [
    'compProps',
    'isQaMode',
    'fullNameCompType',
    'language',
    'mainPageId',
    'compId',
    'experiments',
    'trackClicksAnalytics',
  ],
  ({ compProps, ...rest }, { className, ...carmiData }) => {
    return {
      ...compProps,
      ...carmiData,
      ...rest,
      stylableButtonClassName: className,
      link: carmiData.link && { ...carmiData.link },
    };
  },
);

export const css = withCompInfo()(
  ['compProps'],
  ({ compProps: { isMaxContent, isWrapText } }) => {
    return isMaxContent
      ? {
          height: 'max-content',
          'min-width': isWrapText ? '10px' : 'max-content',
        }
      : {};
  },
);

export const sdkData = withCompInfo<
  StylableButtonSdkData,
  StylableButtonDefinition
>()(['compData', 'topology'], ({ compData, topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();
  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
    svgId: compData.svgId,
  };
});

export const stateRefs = withStateRefsValues<keyof IStylableButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({
  props,
  css,
  sdkData,
  stateRefs,
});
