import * as React from 'react';
import { MouseEvent, KeyboardEvent } from 'react';
import {
  getDataAttributes,
  performOnEnter,
} from '@wix/editor-elements-common-utils';
import SiteMembersInput, {
  ISiteMembersInputRef,
} from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/SiteMembersInput';
import SiteMembersDialogLayout from '@wix/thunderbolt-elements/src/components/SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import {
  serverErrorsHandler,
  validateSiteMembersPassword,
  validateSiteMembersRetypePassword,
} from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/utils';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import { IResetPasswordDialogProps } from '../ResetPasswordDialog.types';
import {
  ResetPasswordDialogTranslationKeys as translationKeys,
  testIds,
} from '../constants';
import style from './style/style.scss';
import { PolicyLink } from './PolicyLink';

const ResetPasswordDialog: React.FC<IResetPasswordDialogProps> = props => {
  const {
    id,
    className,
    isCloseable,
    onSubmitCallback,
    onCloseDialogCallback,
    isPrivacyPolicyNeeded,
    isTermsOfUseNeeded,
    privacyPolicyLink,
    termsOfUseLink,
    directionByLanguage = 'ltr',
    translations,
  } = props;
  const [password, setPassword] = React.useState('');
  const [retypePassword, setRetypePassword] = React.useState('');

  const title = translations[translationKeys.title];
  const subtitle = translations[translationKeys.subtitle];
  const buttonText = translations[translationKeys.buttonText]!;
  const passwordInputText = translations[translationKeys.passwordInputText];
  const retypePasswordInputText =
    translations[translationKeys.retypePasswordInputText];
  const privacyLinkLabel = translations[translationKeys.policies.privacyLink];
  const policiesAndLabel = translations[translationKeys.policies.and];
  const termsOfUseLinkLabel =
    translations[translationKeys.policies.termsOfUseLink];

  const headlineId = `resetPasswordHeadline_${id}`;

  const passwordRef = React.useRef<ISiteMembersInputRef>(null);
  const retypePasswordRef = React.useRef<ISiteMembersInputRef>(null);
  const submitForm = async (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    const isRetypePasswordValid =
      retypePasswordRef.current!.validate(translations);
    const isPasswordValid = passwordRef.current!.validate(translations);
    if (isPasswordValid && isRetypePasswordValid) {
      try {
        await onSubmitCallback(password);
      } catch (error: any) {
        const errorMsg = serverErrorsHandler(error);
        const defaultErrorMsg = translations.membersErrorGeneral;
        retypePasswordRef.current!.setError(
          translations[errorMsg] ?? defaultErrorMsg,
        );
      }
    }
  };
  const validateRetypePassword = () =>
    validateSiteMembersRetypePassword(retypePassword, password, translations);

  const onKeyDownHandler = performOnEnter(submitForm);

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      translations={translations}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
    >
      <form
        id={id}
        className={style.resetPasswordContent}
        onKeyDown={onKeyDownHandler}
      >
        <h3
          id={`resetPasswordHeadline_${id}`}
          className={style.title}
          data-testid={testIds.headline}
        >
          {title}
        </h3>
        <div className={style.contentWrapper}>
          <div className={style.header}>
            <p className={style.subtitle} data-testid="subtitle">
              {subtitle}
            </p>
          </div>
          <div className={style.content}>
            <div className={style.password}>
              <SiteMembersInput
                id={`passwordInput_${id}`}
                inputType="password"
                value={password}
                label={passwordInputText}
                onValueChanged={setPassword}
                isValid={true}
                autoFocus={true}
                ref={passwordRef}
                validationFn={validateSiteMembersPassword.signup}
                directionByLanguage={directionByLanguage}
              />
            </div>
            <div className={style.retypePassword}>
              <SiteMembersInput
                id={`retypePasswordInput_${id}`}
                inputType="password"
                value={retypePassword}
                label={retypePasswordInputText}
                onValueChanged={setRetypePassword}
                isValid={true}
                ref={retypePasswordRef}
                validationFn={validateRetypePassword}
                directionByLanguage={directionByLanguage}
              />
            </div>
          </div>
          <div className={style.actionButton} data-testid="submit">
            <BasicButton
              label={buttonText}
              id={`resetPasswordButton_${id}`}
              isDisabled={false}
              hasPlatformClickHandler={true}
              link={undefined}
              onClick={submitForm}
              data-testid={testIds.reset}
            />
          </div>
          <div className={style.formFooter}>
            {isTermsOfUseNeeded && (
              <PolicyLink
                onCloseDialogCallback={onCloseDialogCallback}
                privacyLinkLabel={termsOfUseLinkLabel}
                testId={testIds.policies.termsOfUseLink}
                link={termsOfUseLink}
              />
            )}
            {isPrivacyPolicyNeeded && isTermsOfUseNeeded && (
              <span data-testid={testIds.policies.and}>{policiesAndLabel}</span>
            )}
            {isPrivacyPolicyNeeded && (
              <PolicyLink
                onCloseDialogCallback={onCloseDialogCallback}
                privacyLinkLabel={privacyLinkLabel}
                testId={testIds.policies.privacyPolicyLink}
                link={privacyPolicyLink}
              />
            )}
          </div>
        </div>
      </form>
    </SiteMembersDialogLayout>
  );
};

export default ResetPasswordDialog;
