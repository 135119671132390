import { withCompController } from '@wix/editor-elements-integrations';
import {
  IMultiStateBoxStateValues,
  IMultiStateBoxProps,
  IMultiStateBoxPlatformProps,
} from '../MultiStateBox.types';

export default withCompController<
  IMultiStateBoxPlatformProps,
  IMultiStateBoxPlatformProps,
  IMultiStateBoxProps,
  IMultiStateBoxStateValues
>(({ mapperProps, stateValues }) => {
  return {
    ...mapperProps,
    observeChildListChange: stateValues.observeChildListChange,
  };
});
