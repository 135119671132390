import { useState, useCallback } from 'react';
import { useCompStyleElementObserver } from '@wix/thunderbolt-elements/providers/useCompStyleElementObserver';
import { MinSize, MenuMode } from '../../StylableHorizontalMenu.types';

type UseMinSizeProps = {
  id: string;
  menuMode: MenuMode;
};

const wrapMinSize = { minWidth: 'min-content' };

const getStyle = (styles: CSSStyleDeclaration | null, key: string) =>
  styles ? Number.parseInt(styles.getPropertyValue(key), 10) || 0 : 0;

const getPaddingsAndBorders = (
  styles: CSSStyleDeclaration | null,
  direction: 'vertical' | 'horizontal',
) => {
  const [suffix1, suffix2] =
    direction === 'vertical' ? ['top', 'bottom'] : ['left', 'right'];

  return (
    getStyle(styles, `padding-${suffix1}`) +
    getStyle(styles, `padding-${suffix2}`) +
    getStyle(styles, `border-${suffix1}-width`) +
    getStyle(styles, `border-${suffix2}-width`)
  );
};

const getNodeMinSize = (nav: Element, selector: string) => {
  const node = nav.querySelector(selector);
  const styles = node && window.getComputedStyle(node);
  const content = (node?.firstChild?.firstChild || {
    clientHeight: 0,
    clientWidth: 0,
  }) as HTMLElement;

  return [
    content.clientWidth + getPaddingsAndBorders(styles, 'horizontal'),
    content.clientHeight + getPaddingsAndBorders(styles, 'vertical'),
  ];
};

const getMenuMinSize = (id: string): MinSize => {
  const nav = document.querySelector(`#${id} nav`);
  if (!nav) {
    return {};
  }

  const navStyles = window.getComputedStyle(nav);
  const [scrollBtnMinWidth, scrollBtnMinHeight] = getNodeMinSize(
    nav,
    '[aria-label="scroll"]',
  );
  const [, menuItemMinHeight] = getNodeMinSize(nav, '[data-item-label]');

  return {
    minWidth:
      getPaddingsAndBorders(navStyles, 'horizontal') +
      2 * scrollBtnMinWidth +
      'px',
    minHeight:
      getPaddingsAndBorders(navStyles, 'vertical') +
      Math.max(scrollBtnMinHeight, menuItemMinHeight) +
      'px',
  };
};

export const useMinSize = ({ id, menuMode }: UseMinSizeProps): MinSize => {
  const [scrollMinSize, setScrollMinSize] = useState(getMenuMinSize(id));
  const isScroll = menuMode === 'scroll';

  useCompStyleElementObserver({
    compId: id,
    shouldObserve: isScroll,
    callback: useCallback(() => setScrollMinSize(getMenuMinSize(id)), [id]),
  });

  return isScroll ? scrollMinSize : wrapMinSize;
};
