import * as React from 'react';
import classNames from 'clsx';
import { SkinNavButtonProps } from '../../SliderGallery.types';
import { TestIds } from '../../constants';
import buttonsStyle from './style/SliderGalleryIronSkinButtons.scss';

const SliderGalleryIronSkinButton: React.FC<SkinNavButtonProps> = props => {
  const {
    ariaLabel,
    extraClassName,
    direction,
    mouseEnter,
    mouseLeave,
    pointerDown,
    keyDown,
    keyUp,
    ariaPressed,
    describedById,
  } = props;

  return (
    <button
      data-testid={
        direction === 'left'
          ? TestIds.swipeLeftHitArea
          : TestIds.swipeRightHitArea
      }
      className={classNames(extraClassName, buttonsStyle.root)}
      type="button"
      aria-label={ariaLabel}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onPointerDown={pointerDown}
      onKeyDown={keyDown}
      onKeyUp={keyUp}
      aria-pressed={ariaPressed}
      aria-describedby={describedById}
    >
      <div
        className={
          direction === 'left'
            ? buttonsStyle.leftArrow
            : buttonsStyle.rightArrow
        }
      />
      <div className={classNames(buttonsStyle.screw, buttonsStyle.screwTL)} />
      <div className={classNames(buttonsStyle.screw, buttonsStyle.screwTR)} />
      <div className={classNames(buttonsStyle.screw, buttonsStyle.screwBL)} />
      <div className={classNames(buttonsStyle.screw, buttonsStyle.screwBR)} />
    </button>
  );
};

export default SliderGalleryIronSkinButton;
