import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IToggleSwitchImperativeActions,
  IToggleSwitchProps,
} from '../ToggleSwitch.types';
import { TestIds } from '../constants';
import semanticClassNames from '../ToggleSwitch.semanticClassNames';
import CheckedIcon from './assets/checkmark-checked.svg';
import UncheckedIcon from './assets/checkmark-unchecked.svg';
import style from './style/ToggleSwitch.scss';

const ToggleSwitch: React.ForwardRefRenderFunction<
  IToggleSwitchImperativeActions,
  IToggleSwitchProps
> = (props, ref) => {
  const {
    id,
    className,
    customClassNames = [],
    label,
    isDisabled,
    checked,
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    hasResponsiveLayout,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [focused, setFocused] = React.useState(false);
  const labelNotEmpty = typeof label === 'string' && label !== '';

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
    };
  });

  const wrapperClassName: string = classNames(style.inputWrapper, {
    [style.disabled]: isDisabled,
    [style.checked]: checked,
    [style.focused]: focused,
  });

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event);
  };

  const _onClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onClick(event);
      inputRef.current?.click();
    }
  };

  const _onDblClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    onFocus(event);
    setFocused(true);
  };

  const _onBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    onBlur(event);
    setFocused(false);
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const interactionEvents = {
    onClick: _onClick,
    onDoubleClick: _onDblClick,
    onMouseEnter: _onMouseEnter,
    onMouseLeave: _onMouseLeave,
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
        style.direction,
        {
          [style.rootWithLabel]: labelNotEmpty,
          [style.notResponsive]: !hasResponsiveLayout,
        },
      )}
      {...(labelNotEmpty ? {} : interactionEvents)}
    >
      {labelNotEmpty && (
        <label
          htmlFor={`input_${id}`}
          className={classNames(
            style.labelText,
            formatClassNames(semanticClassNames.label),
          )}
          data-testid={TestIds.label}
        >
          {label}
        </label>
      )}

      <div
        className={wrapperClassName}
        data-testid={TestIds.switchWrapper}
        {...(labelNotEmpty ? interactionEvents : {})}
      >
        <input
          id={`input_${id}`}
          ref={inputRef}
          type="checkbox"
          className={style.input}
          checked={checked}
          disabled={isDisabled}
          onChange={_onChange}
          onFocus={_onFocus}
          onBlur={_onBlur}
        />
        <div
          data-testid={TestIds.outerLabel}
          className={classNames(
            style.outerLabel,
            formatClassNames(semanticClassNames.track),
          )}
          aria-label="Toggle"
        />
        <div
          className={classNames(
            style.innerLabel,
            formatClassNames(semanticClassNames.handle),
          )}
        >
          {checked ? (
            <CheckedIcon
              data-testid={TestIds.checkedIcon}
              className={style.toggleIcon}
            />
          ) : (
            <UncheckedIcon
              data-testid={TestIds.uncheckedIcon}
              className={style.toggleIcon}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(ToggleSwitch);
