import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import { BreadcrumbsSdkDataCarmiData } from '@wix/thunderbolt-components-native';

import { getMigratedStyleProps } from '@wix/editor-elements-common-utils';

import {
  BreadcrumbsDefinition,
  IBreadcrumbsMapperProps,
  BreadcrumbsCSSVars,
  BreadcrumbsPropsCarmiData,
  IBreadcrumbsStateValues,
} from '../Breadcrumbs.types';

const props = withCompInfo<
  IBreadcrumbsMapperProps,
  BreadcrumbsDefinition,
  BreadcrumbsPropsCarmiData
>()(
  [
    'compProps',
    'styleProperties',
    'compStylableClass',
    'isMobileView',
    'externalBaseUrl',
    'pagesMap',
    'hierarchyPagesMap',
    'mainPageId',
    'editorName',
    'experiments',
    'isDescendantOfBlocksWidget',
  ],
  (
    {
      compProps,
      compStylableClass,
      isMobileView,
      externalBaseUrl,
      pagesMap,
      hierarchyPagesMap,
      mainPageId,
      editorName,
      isDescendantOfBlocksWidget,
    },
    carmiData,
  ) => {
    const {
      separator,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      showOnlyPreviousPageOnMobile,
      itemsBeforeCollapse,
      itemsAfterCollapse,
    } = compProps;

    return {
      hasPageHierarchyBreadcrumbs:
        !isDescendantOfBlocksWidget && editorName === 'Studio',
      svgString: carmiData.svgString,
      pagesMenuItems: carmiData.pagesMenuItems,
      pagesMap,
      hierarchyPagesMap,
      mainPageId,
      separator,
      shouldWrap,
      showHomePage,
      showCurrentPage,
      showHomePageAsIcon,
      itemsBeforeCollapse,
      itemsAfterCollapse,
      showOnlyPreviousPageOnMobile:
        isMobileView && showOnlyPreviousPageOnMobile,
      isMobile: isMobileView,
      stylableClassName: compStylableClass,
      externalBaseUrl,
    };
  },
);

const css = withCompInfo<BreadcrumbsCSSVars, BreadcrumbsDefinition>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }) => {
    const { horizontalMargin, verticalMargin } = compProps;

    const { direction, alignment } = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields: ['direction', 'alignment'],
    });

    return {
      '--horizontalMargin': `${horizontalMargin}px`,
      '--verticalMargin': `${verticalMargin}px`,
      '--alignment': alignment,
      '--direction': direction,
      height: 'auto', // component is not resizable vertically and should have content height
    };
  },
);

const sdkData = withCompInfo<
  BreadcrumbsSdkDataCarmiData,
  BreadcrumbsDefinition
>()(['topology'], ({ topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();

  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
  };
});

const stateRefs = withStateRefsValues<keyof IBreadcrumbsStateValues>([
  'currentUrl',
  'pageId',
  'setVeloSeoTags',
  'previewMode',
]);

export default createComponentMapperModel({ css, props, sdkData, stateRefs });
