import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  IMultiStateBoxPlatformProps,
  MultiStateBoxDefinition,
} from '../MultiStateBox.types';
import { getStateToRender } from './utils';

export const props = withCompInfo<
  IMultiStateBoxPlatformProps,
  MultiStateBoxDefinition,
  IMultiStateBoxPlatformProps
>()(
  ['compId', 'compData', 'structure', 'experiments'],
  ({ compId, compData, structure, experiments }, carmiData) => {
    const { a11y, defaultStateId, selectedStateId } = compData;

    const stateIds = structure.components ?? [];

    return {
      ...carmiData,
      a11y,
      selectedStateId: getStateToRender({
        defaultStateId,
        selectedStateId,
        stateIds,
        msbId: compId,
        isEditing: false,
      }),
      shouldFixResponsiveBoxContainerLayoutClassName:
        experiments[
          'specs.thunderbolt.fixResponsiveBoxContainerLayoutClass'
        ] === true,
    };
  },
);

export const stateRefs = withStateRefsValues(['observeChildListChange']);

export default createComponentMapperModel({ props, stateRefs });
