import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  migrateFields,
  isExperimentOpen,
  renderAllTabsExperiment,
  getFocusRingValue,
} from '@wix/editor-elements-common-utils';
import { contentAlignmentMap } from '../constants';
import { ITabsDefinition } from '../documentManagement/Tabs.definition';
import {
  ITabsCarmiData,
  ITabsMapperProps,
  ITabsProperties,
  isVerticalProperties,
} from '../Tabs.types';
import { toBoolean } from '../utils';
import {
  getFlexDirectionVars,
  getMenuModeCSSVars,
  getTabsListAlignmentCssVars,
  getTabsListDirectionCSSVars,
  sumArgs,
  toPxValue,
} from './TabsMapperUtils';

export const props = withCompInfo<
  ITabsMapperProps,
  ITabsDefinition,
  ITabsCarmiData
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'hasResponsiveLayout',
    'experiments',
  ],
  (
    {
      compData,
      compProps,
      compStylableClass,
      hasResponsiveLayout,
      experiments,
    },
    carmiData,
  ) => {
    const { tabItems, containerProps, containerRootClassName } = carmiData;
    const { defaultTabId } = compData;

    return {
      ...compData,
      ...compProps,
      stylableClassName: compStylableClass,
      tabItems,
      currentTabId: defaultTabId,
      hasResponsiveLayout,
      containerProps,
      containerRootClassName,
      shouldRenderAllTabs: isExperimentOpen(
        experiments,
        renderAllTabsExperiment,
      ),
      shouldFixResponsiveBoxContainerLayoutClassName:
        experiments[
          'specs.thunderbolt.fixResponsiveBoxContainerLayoutClass'
        ] === true,
    };
  },
);

export const css = withCompInfo<
  any,
  ITabsDefinition,
  never,
  {
    styleProperties: ITabsProperties;
  }
>()(
  ['compProps', 'hasResponsiveLayout', 'styleProperties'],
  ({ styleProperties, hasResponsiveLayout }) => {
    const {
      menuMode,
      itemsDirection = 'ltr',
      itemsOrientation = 'horizontal',
      itemsAlignment,
      containerSpacing,
      itemSpacing,
      contentAlignment,
      horizontalTabPadding,
      verticalTabPadding,
      stretchTabsToMenuWidth,
      itemRowSpacing,
    } = styleProperties;
    const isHorizontal = itemsOrientation === 'horizontal';
    const isRtl = itemsDirection === 'rtl';

    const responsiveContainerVars = {
      '--rd': '0',
      '--brw': '0',
      '--shd': 'none',
      '--bg': 'transparent',
    };

    const gapVars = isHorizontal
      ? {
          '--tabs-list-items-col-gap': toPxValue(itemSpacing),
          '--tabs-list-items-row-gap': toPxValue(itemRowSpacing),
        }
      : {
          '--tabs-list-items-row-gap': toPxValue(itemSpacing),
        };

    const tabsListCommonLayoutCSSVars = {
      ...gapVars,
      '--tabs-list-container-gap': toPxValue(containerSpacing),
      '--tabs-list-content-alignment':
        contentAlignmentMap[itemsDirection][contentAlignment],
      '--tabs-list-horizontal-padding': toPxValue(horizontalTabPadding),
      '--tabs-list-vertical-padding': toPxValue(verticalTabPadding),
      '--tabs-list-item-flex-grow': toBoolean(stretchTabsToMenuWidth)
        ? '1'
        : '0',
      '--tabs-list-orientation': itemsOrientation,
      '--tabs-list-direction': itemsDirection,
    };

    const tabsListLayoutCSSVars = {
      ...tabsListCommonLayoutCSSVars,
      ...(isVerticalProperties(styleProperties)
        ? {
            '--tabs-list-width': toPxValue(
              sumArgs(styleProperties.tabWidth, horizontalTabPadding),
            ),
            '--tabs-list-height': '100%',
            '--tabs-list-position': 'absolute',
            '--tabs-position': 'relative',
            [`--tabs-padding-${isRtl ? 'right' : 'left'}`]: toPxValue(
              sumArgs(
                styleProperties.tabWidth,
                horizontalTabPadding,
                containerSpacing,
              ),
            ),
            '--tab-label-overflow': 'hidden',
            '--tab-label-text-overflow': 'ellipsis',
          }
        : {
            '--tabs-list-width': '100%',
            '--tabs-list-height': 'auto',
            '--tabs-list-position': 'relative',
          }),
    };

    const menuModeCSSVars = getMenuModeCSSVars(
      menuMode,
      isHorizontal,
      itemsAlignment,
      isRtl,
    );

    const tabsDirectionVars = getFlexDirectionVars(isHorizontal, isRtl);

    const tabsListAlignmentCssVars = getTabsListAlignmentCssVars(
      menuMode,
      isHorizontal,
      itemsAlignment,
      isRtl,
    );

    const tabsListDirectionVars = getTabsListDirectionCSSVars(
      itemsDirection,
      isHorizontal,
    );

    return {
      height: 'auto',
      ...(hasResponsiveLayout
        ? {
            ...responsiveContainerVars,
            // For responsive vertical layout, adjust SingleTab width to fit Tabs container when switching from horizontal.
            // Set wrapper width to 0px, so width is determined by flex: 1.
            '--multi-state-wrapper-width': isHorizontal ? '100%' : '0px',
          }
        : {
            ...(!isHorizontal && {
              'min-width': 'fit-content',
              'max-width': 'fit-content',
            }),
            '--multi-state-wrapper-width': '100%',
          }),
      ...menuModeCSSVars,
      ...tabsDirectionVars,
      ...tabsListLayoutCSSVars,
      ...tabsListAlignmentCssVars,
      ...tabsListDirectionVars,
      '--focus-ring': getFocusRingValue(menuMode === 'wrap'),
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [
          'menuMode',
          'itemsDirection',
          'itemsOrientation',
          'itemsAlignment',
          'containerSpacing',
          'itemSpacing',
          'contentAlignment',
          'horizontalTabPadding',
          'verticalTabPadding',
          'stretchTabsToMenuWidth',
          'itemRowSpacing',
          'tabWidth',
          'itemsOrientation',
        ],
      },
    ]),
  ],
);

export const stateRefs = withStateRefsValues(['observeChildListChange']);

export default createComponentMapperModel({ props, css, stateRefs });
