import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getInputHeight,
  LogicalAlignment,
  WithInherit,
  migrateFields,
  getLabelPaddingsValues,
  castFromInheritIfNeeded,
  PhysicalAlignment,
  convertPhysicalInputAlignmentToLogical,
  addUnitToEveryField,
  convertPhysicalInputAlignmentToDirection,
  getHeightInPixels,
} from '@wix/editor-elements-common-utils';
import {
  AddressInputDefinition,
  AddressInputMapperProps,
  AddressInputCSSVars,
  AddressInputCssCarmiData,
  IAddressInputRefState,
} from '../AddressInput.types';
import {
  ariaNamespace,
  TranslationKeys,
  TranslationFallbacks,
} from './constants';

export const props = withCompInfo<
  AddressInputMapperProps,
  AddressInputDefinition
>()(
  ['compData', 'compProps', 'language', 'externalBaseUrl', 'translate'],
  ({ compData, compProps, language, externalBaseUrl, translate }) => {
    return {
      placeholder: compData.placeholder,
      readOnly: compProps.readOnly,
      required: compProps.required,
      isDisabled: compProps.isDisabled,
      iconVisible: compProps.iconVisible,
      dividerVisible: compProps.dividerVisible,
      country: compData.filter?.country,
      alignment: compProps.alignment,
      lang: language,
      label: compData.label,
      provider: 'atlas',
      externalBaseUrl,
      translations: {
        noResults:
          translate(ariaNamespace, TranslationKeys.noResults) ||
          TranslationFallbacks.noResults,
        generalError:
          translate(ariaNamespace, TranslationKeys.generalError) ||
          TranslationFallbacks.generalError,
        exampleAddressText:
          translate(ariaNamespace, TranslationKeys.exampleAddressText) ||
          TranslationFallbacks.exampleAddressText,
      },
    };
  },
);

export const css = withCompInfo<
  AddressInputCSSVars,
  AddressInputDefinition,
  AddressInputCssCarmiData
>()(
  [
    'styleProperties',
    'compProps',
    'compLayout',
    'compSingleLayout',
    'isMobileView',
    'hasResponsiveLayout',
    'experiments',
    'compData',
    'isOneDocMigrated',
  ],
  (
    {
      styleProperties,
      compProps,
      compLayout,
      compSingleLayout,
      isMobileView,
      hasResponsiveLayout,
      compData,
      isOneDocMigrated,
    },
    cssVars,
  ) => {
    const { margin, labelMargin, labelPadding } = compProps;

    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      optionsDirection = 'inherit',
    } = compData;

    const { align, labelAlign, inputAlign, optionsAlign } =
      styleProperties as Record<string, string> & {
        align: LogicalAlignment;
        inputAlign: WithInherit<LogicalAlignment>;
        labelAlign: WithInherit<LogicalAlignment>;
        optionsAlign: WithInherit<LogicalAlignment>;
      };

    const calculatedLabelAlign = castFromInheritIfNeeded(labelAlign, align);
    const calculatedInputAlign = castFromInheritIfNeeded(inputAlign, align);
    const calculatedOptionsAlign = castFromInheritIfNeeded(optionsAlign, align);

    const labelPaddingValues = getLabelPaddingsValues({
      labelPadding,
      align: calculatedLabelAlign,
    });

    const optionsAndInputPadding =
      margin ?? styleProperties.dropdownOptionPadding;

    const optionsPadding =
      calculatedOptionsAlign === 'center' ? 0 : optionsAndInputPadding;

    const inputPadding =
      calculatedInputAlign === 'center' ? 0 : optionsAndInputPadding;

    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;

    return {
      ...cssVars,
      height: 'auto',
      '--optionsPadding': `${optionsPadding}px`,
      '--inputPadding': `${inputPadding}px`,
      '--direction': direction,
      '--align': align,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--optionsDirection': optionsDirection,
      '--labelMarginBottom': `${labelMargin}px`,
      ...addUnitToEveryField(labelPaddingValues, labelPadding),
      '--inputHeight': hasResponsiveLayout
        ? undefined
        : getInputHeight({
            inputHeightProps: compProps,
            height,
            isMobileView,
          }),
      '--inputFlex': hasResponsiveLayout ? 1 : undefined,
      '--inputContainerHeight': hasResponsiveLayout ? '100%' : undefined,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'alignment', target: 'align' }],
        enhancer: alignment =>
          convertPhysicalInputAlignmentToLogical(
            alignment as PhysicalAlignment,
          ),
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'alignment', target: 'direction' }],
        enhancer: alignment =>
          convertPhysicalInputAlignmentToDirection(
            alignment as PhysicalAlignment,
          ),
      },
    ]),
  ],
);

export const stateRefs = withStateRefsValues<keyof IAddressInputRefState>([
  'domain',
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
