interface Pattern {
  rows: Array<Row>;
}

interface Row {
  cells: Array<Cell>;
}

interface Cell {
  proportion: number;
}

export const firstPattern: Pattern = {
  rows: [
    {
      cells: [{ proportion: 2 }, { proportion: -1 }, { proportion: 1 }],
    },
    {
      cells: [{ proportion: 1 }, { proportion: 2 }, { proportion: 1 }],
    },
  ],
};

export const secondPattern: Pattern = {
  rows: [
    {
      cells: [{ proportion: 2 }, { proportion: 1 }],
    },
    {
      cells: [{ proportion: 1 }, { proportion: -1 }, { proportion: 1 }],
    },
    {
      cells: [{ proportion: 1 }, { proportion: 1 }, { proportion: 1 }],
    },
  ],
};

// calculates the least common denominator of two values
const getLcd = (a: number, b: number): number => {
  let d = a * b;
  if (!d) {
    return d;
  }
  for (let n = Math.min(a, b); n < d; n++) {
    if (a % n === 0 && b % n === 0) {
      d = n;
    }
  }
  return d;
};

// calculates the sum of all 'proportion' values in a row
const getRowProportions = (row: Row) =>
  row.cells.reduce((p, c) => p + Math.abs(c.proportion), 0);

// calculates the least common denominator for all rows
const getAllRowsLcd = (rows: Array<Row>) =>
  rows.reduce((p, c) => {
    const pp = getRowProportions(c);
    return p ? getLcd(p, pp) : pp;
  }, 0);

export const buildGalleryPatternRules = (
  compId: string,
  pattern: Pattern,
  parentSelector: string,
  itemSelector: string,
  flip: boolean,
  mode: 'horizontal' | 'vertical' = 'vertical',
  gap: number = 0,
): string => {
  const maxColumns = getAllRowsLcd(pattern.rows);
  const rulePrefix = mode === 'vertical' ? 'column' : 'row';
  const parentRule = `#${compId} .${parentSelector} {
    grid-template-${rulePrefix}s: repeat(${maxColumns}, 1fr);
    display: grid;
    grid-auto-flow: ${mode === 'vertical' ? 'row' : 'column'};
    gap: ${gap}px;
  } `;
  let childRules = ``;
  const patternCellCount = pattern.rows.reduce(
    (rp, rc) =>
      rp + rc.cells.reduce((cp, cc) => cp + (cc.proportion > 0 ? 1 : 0), 0),
    0,
  );
  const cellCount = patternCellCount * (flip ? 2 : 1);
  let cellIndex = 1;
  for (let flipIndex = 0; flipIndex < (flip ? 2 : 1); flipIndex++) {
    const isFlip = flipIndex > 0;
    for (const row of pattern.rows) {
      let cellPosition = 0;
      const cellWeight = maxColumns / getRowProportions(row);
      const cells = isFlip ? [...row.cells].reverse() : row.cells;
      for (const cell of cells) {
        if (cell.proportion > 0) {
          if (childRules) {
            childRules += ' ';
          }
          const startColumn = cellPosition + 1;
          const endColumn = startColumn + cellWeight * cell.proportion;
          childRules += `#${compId} .${itemSelector}:nth-child(${cellCount}n+${cellIndex}) { grid-${rulePrefix}: ${startColumn}/${endColumn}; }`;
          cellIndex++;
        }
        cellPosition += Math.abs(cell.proportion) * cellWeight;
      }
    }
  }

  return parentRule + childRules;
};

export const formatPattern = (inputString: string): Pattern => {
  try {
    const regex = /\[([-?\d\s,]+)\]/g;
    const matches = inputString.matchAll(regex);

    const rows = Array.from(matches, match => {
      const row = match[1]
        .split(',')
        .map(cell => ({ proportion: parseInt(cell.trim(), 10) }));
      return { cells: row };
    });

    return { rows };
  } catch (e) {
    return firstPattern;
  }
};
