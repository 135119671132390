import * as React from 'react';
import classnames from 'clsx';
import { sortBy } from 'lodash';
import { testIds } from '@wix/thunderbolt-elements/src/components/SignUpDialog/viewer/constants';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import {
  IMode,
  ISocialAuthButtons,
  IdpConnection,
} from '../MemberLoginDialog.types';
import {
  IDP_APPDEFIDS,
  SiteMembersTranslations,
  MemberLoginDialogTranslationKeys as keys,
} from './utils';
import Facebook from './assets/f_logo.svg';
import Google from './assets/google.svg';
import { useIDPPopup } from './useIDPPopup';

export const SocialAuthButtons: React.FC<ISocialAuthButtons> = ({
  id,
  idps,
  iframeConfig,
  style,
  mode,
  translations,
  isHorizontal,
  error, // Will be set to a non-empty string if an error was thrown during social auth
  hideSSOButton,
  reportSocialAuthStarted,
  onBackendSocialLogin,
  onSocialError,
}) => {
  const openPopup = useIDPPopup(
    iframeConfig,
    reportSocialAuthStarted,
    onBackendSocialLogin,
    onSocialError,
  );

  return (
    <div className={style.socialLoginWrapper}>
      <>
        {sortBy(idps, orderIdp).map(idp => (
          <IDPButton
            key={idp.id}
            isHorizontal={isHorizontal}
            style={style}
            openPopup={openPopup}
            idp={idp}
            translations={translations}
            mode={mode}
            id={id}
            hideSSOButton={hideSSOButton}
          />
        ))}
      </>
      <p className={style.socialLoginErrorMsg}>{error}</p>
    </div>
  );
};

// We always want to show Google first, then Facebook, then the rest
const orderIdp = (idp: IdpConnection) => {
  if (idp.appDefId === IDP_APPDEFIDS.google) {
    return 1;
  }
  if (idp.appDefId === IDP_APPDEFIDS.facebook) {
    return 2;
  }
  return 3;
};

type IDPButtonProps = {
  isHorizontal: boolean | undefined;
  style: {
    [key: string]: string;
  };
  openPopup: (idpConnectionId: string) => Promise<void>;
  idp: IdpConnection;
  mode: IMode;
  id: string;
  hideSSOButton?: boolean;
} & SiteMembersTranslations;

const IDPButton: React.FC<IDPButtonProps> = ({
  isHorizontal,
  style,
  openPopup,
  idp,
  translations,
  mode,
  id,
  hideSSOButton,
}) => {
  if (idp.appDefId === IDP_APPDEFIDS.google) {
    return (
      <GoogleButton
        isHorizontal={isHorizontal}
        style={style}
        openPopup={openPopup}
        idp={idp}
        translations={translations}
        mode={mode}
        id={id}
      />
    );
  } else if (idp.appDefId === IDP_APPDEFIDS.facebook) {
    return (
      <FacebookButton
        isHorizontal={isHorizontal}
        style={style}
        openPopup={openPopup}
        idp={idp}
        translations={translations}
        mode={mode}
        id={id}
      />
    );
  } else {
    return !hideSSOButton ? (
      <SSOButton
        isHorizontal={isHorizontal}
        style={style}
        openPopup={openPopup}
        idp={idp}
        translations={translations}
        mode={mode}
        id={id}
      />
    ) : null;
  }
};

const SSOButton: React.FC<IDPButtonProps> = ({
  style,
  openPopup,
  idp,
  translations,
  mode,
}) => {
  return (
    <div
      data-testid={testIds.ssoBtn}
      className={classnames(style.expandButton, style.ssoWrapper)}
    >
      <button
        className={style.sso}
        onClick={() => openPopup(idp.id)}
        type="button"
      >
        {translations[keys.social.sso[mode]] ?? ''}
      </button>
    </div>
  );
};

const GoogleButton: React.FC<IDPButtonProps> = ({
  isHorizontal,
  style,
  openPopup,
  idp,
  translations,
  mode,
  id,
}) => {
  return isHorizontal ? (
    <Google
      data-testid="googlenew-small"
      className={classnames(style.socialIcon, style.google)}
      onClick={() => openPopup(idp.id)}
    />
  ) : (
    <div
      data-testid="googlenew"
      className={classnames(style.socialButton, style.google)}
    >
      <BasicButton
        label={translations[keys.social.google[mode]] ?? ''}
        id={`google${id}`}
        link={undefined}
        hasPlatformClickHandler={true}
        isDisabled={false}
        onClick={() => openPopup(idp.id)}
      />
    </div>
  );
};

const FacebookButton: React.FC<IDPButtonProps> = ({
  isHorizontal,
  style,
  openPopup,
  idp,
  translations,
  mode,
  id,
}) => {
  return isHorizontal ? (
    <Facebook
      data-testid="fbnew-small"
      className={classnames(style.socialIcon, style.facebook)}
      onClick={() => openPopup(idp.id)}
    />
  ) : (
    <div
      data-testid="fbnew"
      className={classnames(style.socialButton, style.facebook)}
    >
      <BasicButton
        label={translations[keys.social.facebook[mode]] ?? ''}
        id={`facebook_${id}`}
        link={undefined}
        hasPlatformClickHandler={true}
        isDisabled={false}
        onClick={() => openPopup(idp.id)}
      />
    </div>
  );
};
