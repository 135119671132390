import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  addUnitToEveryField,
  convertDirectionToLogicalAlignment,
  getHeightPixelValue,
  getLabelPaddingsValues,
  getWidthPixelValue,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import {
  SliderCSSVars,
  SliderDefinition,
  ISliderMapperProps,
  SliderStyleProperties,
} from '../Slider.types';
import {
  SliderTickMarksShape,
  SliderTickMarksPosition,
  SliderThumbShape,
} from '../documentManagement/Slider.schemaTypes';
import { Sizes } from '../constants';

export const props = withCompInfo<ISliderMapperProps, SliderDefinition>()(
  ['compData', 'compProps', 'compLayout', 'compSingleLayout', 'isMobileView'],
  ({ compData, compProps, compLayout, compSingleLayout, isMobileView }) => {
    const { min, max, value, step, stepType, readOnly } = compData;
    const {
      isDisabled,
      thumbShape,
      thumbSize,
      tickMarksShape,
      tickMarksPosition,
      tooltipPosition,
      tooltipVisibility,
      tooltipPrefix,
      tooltipSuffix,
      label,
      labelMargin,
    } = compProps;

    const widthValue = getWidthPixelValue(compLayout, compSingleLayout);
    const heightValue = getHeightPixelValue(compLayout, compSingleLayout);

    // TODO: support responsive layout
    const width = widthValue || 0;
    const height = heightValue || 0;

    return {
      width,
      height,
      min,
      max,
      value,
      step,
      stepType,
      isDisabled,
      readOnly,
      thumbShape,
      thumbSize,
      tickMarksShape,
      tickMarksPosition,
      tooltipPosition: isMobileView ? 'normal' : tooltipPosition,
      tooltipVisibility,
      tooltipPrefix,
      tooltipSuffix,
      label,
      labelMargin,
    };
  },
);

const getTicksCssByShape = (tickMarksShape: SliderTickMarksShape) => {
  if (tickMarksShape === 'line') {
    return {
      '--tickWidth': `${Sizes.tickLineWidth}px`,
      '--tickHeight': `${Sizes.tickLineHeight}px`,
    };
  }
  return {
    '--tickWidth': `${Sizes.tickDotSize}px`,
    '--tickHeight': `${Sizes.tickDotSize}px`,
    '--tickBorderRadius': '50%',
  };
};

const getTicksCssByPosition = (tickMarksPosition: SliderTickMarksPosition) => {
  return {
    normal: { '--tickBottom': '0' },
    middle: { '--tickTop': '50%', '--tickTranslateY': '-50%' },
    across: { '--tickTop': '0' },
  }[tickMarksPosition];
};

const getThumbBorderRadius = (
  styleProperties: Record<string, string>,
  thumbShape: SliderThumbShape,
) => {
  if (thumbShape === 'circle') {
    return '50%';
  }
  const defaultBorderRadiusByShape = {
    circle: '50%',
    square: '17px',
    rectangle: '37px',
    bar: '5px',
  };
  return (
    styleProperties.thumbBorderRadius || defaultBorderRadiusByShape[thumbShape]
  );
};

type Overrides = {
  styleProperties: SliderStyleProperties;
};

export const css = withCompInfo<
  SliderCSSVars,
  SliderDefinition,
  never,
  Overrides
>()(
  ['compProps', 'compData', 'styleProperties', 'isOneDocMigrated'],
  ({ compProps, compData, styleProperties, isOneDocMigrated }) => {
    const {
      trackSize,
      thumbShape,
      thumbSize,
      tickMarksPosition,
      tickMarksShape,
      labelMargin,
      labelPadding,
    } = compProps;

    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      tooltipDirection = 'inherit',
      trackDirection = compProps.dir ?? 'inherit',
    } = compData;
    const { align } = styleProperties;

    const labelPaddingValues = getLabelPaddingsValues({
      labelPadding,
      align,
    });

    return {
      ...(thumbSize && !isOneDocMigrated && { height: 'auto' }),
      '--direction': direction,
      '--labelDirection': labelDirection,
      '--trackDirection': trackDirection,
      '--tooltipDirection': tooltipDirection,
      '--align': align,
      '--labelMarginBottom': `${labelMargin}px`,
      '--trackHeight': `${trackSize}%`,
      '--thumbBorderRadius': getThumbBorderRadius(styleProperties, thumbShape),
      ...getTicksCssByPosition(tickMarksPosition),
      ...(tickMarksShape !== 'none' && getTicksCssByShape(tickMarksShape)),
      ...addUnitToEveryField(labelPaddingValues, labelPadding),
    };
  },

  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'dir', target: 'align' }],
        enhancer: convertDirectionToLogicalAlignment,
      },
    ]),
  ],
);

export default createComponentMapperModel({ css, props });
