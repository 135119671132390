import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { convertHexaOrHexToRGBAUnits } from '@wix/editor-elements-common-utils';
import { RepeaterDefinition } from '@wix/thunderbolt-components';

import { IStudioRepeaterCSSVars } from '../ResponsiveRepeater.types';

export const css = withCompInfo<
  IStudioRepeaterCSSVars,
  RepeaterDefinition,
  Record<string, any>
>()(
  ['styleProperties', 'editorName'],
  ({ editorName, styleProperties }, carmiData = {}) => {
    const { shd, bg, brd, brw, rd } = styleProperties;
    const borderColorRGB = convertHexaOrHexToRGBAUnits(brd)?.slice(0, 3);
    const isShadowEnabled = styleProperties['boxShadowToggleOn-shd'] === 'true';

    if (editorName === 'Studio') {
      return {
        ...carmiData,
        '--boxShadow': isShadowEnabled ? shd : 'none',
        '--backgroundColor': bg,
        '--borderColor': borderColorRGB,
        '--borderWidth': brw,
        '--borderRadius': rd,
        '--alpha-borderColor': styleProperties['alpha-brd'],
      };
    }

    return carmiData;
  },
);

export const stateRefs = withStateRefsValues(['observeChildListChange']);

export default createComponentMapperModel({ css, stateRefs });
