
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Tabscomponent1176390459",
      {"classes":{"root":"Tabscomponent1176390459__root","tabsList":"Tabscomponent1176390459__tabsList","multiStateBoxWrapper":"Tabscomponent1176390459__multiStateBoxWrapper","tabPanelWrapper":"Tabscomponent1176390459__tabPanelWrapper","tabPanelWrapperExperiment":"Tabscomponent1176390459__tabPanelWrapperExperiment"},"vars":{"display":"--display"},"stVars":{"tabsListContainerGap":"var(--tabs-list-container-gap, 0)","tabsPosition":"var(--tabs-position, unset)","tabsPaddingLeft":"var(--tabs-padding-left, 0)","tabsPaddingRight":"var(--tabs-padding-right, 0)","tabsContainerFlexDirection":"var(--tabs-container-flex-direction, column)","multiStateWrapperWidth":"var(--multi-state-wrapper-width, 100%)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  